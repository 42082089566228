import * as React from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BuildIcon from '@mui/icons-material/Build';
import MenuIcon from '@mui/icons-material/Menu';
import {
  BrowserRouter,
  Routes,
  Route,
  NavLink,
  useSearchParams,
} from "react-router-dom";
import './main-page.scss'
import Landing from '../landing/landing';
import { getAnalytics, logEvent } from "firebase/analytics";
import { Alert, CircularProgress,  Toolbar, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { App, AppConfigs, MenuLinks, RoleTypes } from '../../models/shared_models';
import ErrorPage from '../error/error';
import { auth, getUserRoles, isLocal, openUserSubPortal } from '../../services/web.service';
import { onAuthStateChanged } from "firebase/auth";
import { updateAppState } from '../../app/states/appState';
import { useEffect } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HelpIcon from '@mui/icons-material/Help';
//import jwt_decode from "jwt-decode";
import SubProgress from '../../components/subProgress/subProgress';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import BookIcon from '@mui/icons-material/Book';
import HelpPage from '../help/help';
import Training from '../training/training';
import TrainingEditor from '../training/trainingEditor/trainingEditor';
import useDoOnce from '../../services/hooks/useDoOnce';
import CoprateImageLandingPage from '../landing/CoporateImageLandingPage';
import InfoIcon from '@mui/icons-material/Info';

declare const fbq: any;
const drawerWidth = 240;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(0)} + 0px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 0px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    backgroundColor: '#FFFFFF',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

function MainPage() {
  const mobile = useMediaQuery('(min-width:600px)');
  const [open, setOpen] = React.useState<boolean>(mobile);
  const appState: App = useSelector((state: any) => state.appState.app as App);
  const doOnce = useDoOnce()
  const dispatch = useDispatch()
  //const navigate = useNavigate();
  const Editor = React.lazy(() => import('../editor/editor'));
  const Projects = React.lazy(() => import('../projects/projects'));
  const MyGallery = React.lazy(() => import('../my-gallery-page/my-gallery-page'));
  const Pricing = React.lazy(() => import('../pricing/pricing'));
  const TNC = React.lazy(() => import('../tnc/tnc'));
  const analytics = getAnalytics();
  // component mounting and unmounting
  const [menuConfig, setMenuConfig] = React.useState<MenuLinks[]>([]);

  useEffect(() => {

    // check if user is logged in
    //if (!auth) {
    const unsub = onAuthStateChanged(auth, async (user: any) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        dispatch(updateAppState({ loading: true }))
        // const userRole = (jwt_decode(user.accessToken) as any).stripeRole || RoleTypes.FREE
        // get user roles
        const userRole = await getUserRoles(uid) as RoleTypes;

        // do tracking for new signups and purchases.
        if (user.emailVerified && userRole == RoleTypes.FREE) {
          if (doOnce('join_group')) {
            logEvent(analytics, 'join_group', { name: 'join_group' });
            fbq('track', 'CompleteRegistration');
          }

        } else if (user.emailVerified && userRole != RoleTypes.FREE) {
          if (doOnce('purchase')) {
            logEvent(analytics, 'purchase');
            fbq('track', 'Purchase', { value: 19.99, currency: 'USD' });
          }
        }
        if (doOnce('login')) {
          logEvent(analytics, 'login');
          fbq('track', 'Lead');
        }
        console.log("Logged In", user, userRole)
        dispatch(updateAppState({ loading: false, emailVerified: user.emailVerified, loggedIn: true, email: user.email, uid: uid, role: userRole }))
        // ...
      } else {
        // User is signed out
        // ...
        console.log("Logged Out")
        dispatch(updateAppState({ loggedIn: false, email: false }))

      }
    });
    // }

    //check query params and store in local session
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const config = params.get('setConfig');
    const configLocalSession = localStorage.getItem("CONFIG");
    if (config) {
      localStorage.setItem("CONFIG", config);
      dispatch(updateAppState({ appConfig: config }))
    } else if (configLocalSession) {
      // if sesson storage is alredy set, use from local strage instead.
      dispatch(updateAppState({ appConfig: configLocalSession }))
    } else {
      //we will set default
      dispatch(updateAppState({ appConfig: AppConfigs.DEFAULT }))
    }

    return () => {
      // Anything in here is fired on component unmount.
      unsub();
    }
  }, [])

  // trigger when alert message is dsplayed.
  useEffect(() => {

    if (appState.errorMsg) {
      setTimeout(() => {
        dispatch(updateAppState({ errorMsg: "" }))
      }, 5000)
    }

    return () => {
      // Anything in here is fired on component unmount.

    }
  }, [appState.errorMsg])



  // trigger when alert message is dsplayed.
  useEffect(() => {
    let configsmenu: MenuLinks[];
    if (appState.appConfig == AppConfigs.DEFAULT) {
      configsmenu = [
        { linkName: "Create", route: { to: "/projects" }, IconComponent: <AutoFixHighIcon />, show: true },
        { linkName: "My Gallery", route: { to: "/my-gallery" }, IconComponent: <FavoriteIcon />, show: appState.emailVerified && appState.loggedIn },
        { linkName: "Fine Tune", route: { to: "/training" }, IconComponent: <BuildIcon />, show: appState.emailVerified && appState.loggedIn },
        { linkName: "Tutorials", route: { to: "/x" },  func: gotToWeb('https://blog.artius.studio/videos/'), IconComponent: <HelpIcon />, show: true},
        { linkName: "Blog", route: { to: "/x" },  func: gotToWeb('https://blog.artius.studio/'), IconComponent: <BookIcon />, show: true},
        { linkName: "About Us", route: { to: "/x" },  func: gotToWeb('https://blog.artius.studio/about-us/'), IconComponent: <InfoIcon />, show: true},

        { linkName: "Billing", route: { to: "/a" }, IconComponent: <CreditCardIcon />, func: openSubPortal, show: appState.emailVerified && appState.loggedIn },
        { linkName: "Logout", route: { to: "/b" }, IconComponent: <LogoutIcon />, func: logOut, show: appState.loggedIn },

      ]
      setMenuConfig(configsmenu)
    }else if (appState.appConfig == AppConfigs.PROFILE_PICS){
      configsmenu = [
        { linkName: "Upload My Pictures", route: { to: "/training" }, IconComponent: <UploadFileIcon />, show: appState.emailVerified && appState.loggedIn },
        { linkName: "Create", route: { to: "/projects" }, IconComponent: <AutoFixHighIcon />, show: true },
        { linkName: "My Gallery", route: { to: "/my-gallery" }, IconComponent: <FavoriteIcon />, show: appState.emailVerified && appState.loggedIn },
        { linkName: "Tutorials", route: { to: "/x" },  func: gotToWeb('https://blog.artius.studio/videos/'), IconComponent: <HelpIcon />, show: true},
        { linkName: "Blog", route: { to: "/x" },  func: gotToWeb('https://blog.artius.studio/'), IconComponent: <BookIcon />, show: true},
        { linkName: "About Us", route: { to: "/x" },  func: gotToWeb('https://blog.artius.studio/about-us/'), IconComponent: <InfoIcon />, show: true},

        { linkName: "Billing", route: { to: "/a" }, IconComponent: <CreditCardIcon />, func: openSubPortal, show: appState.emailVerified && appState.loggedIn },
        { linkName: "Logout", route: { to: "/b" }, IconComponent: <LogoutIcon />, func: logOut, show: appState.loggedIn },

      ]
      setMenuConfig(configsmenu)
    }

    return () => {
      // Anything in here is fired on component unmount.

    }
  }, [appState.emailVerified, appState.loggedIn, appState.appConfig])


const gotToWeb = ( url : string)=>{
  // go to web
  return ()=>{ window.open(url);}
}

  const activeStyle = {
    color: "white",
    display: "block",
    background: "linear-gradient(90deg, #8700F9 0%, #E30DFD 100%)",
    borderRight: "white 3px solid"
  };

  const handleDrawerToggle = () => {
    setOpen((prevState) => !prevState);
  };

  const logOut = () => {
    dispatch(updateAppState({ loading: true }))
    auth.signOut().then(function () {
      // Sign-out successful.
      dispatch(updateAppState({ loading: false }))
      //navigate("/");
    }, function (error) {
      // An error happened.
      dispatch(updateAppState({ loading: false }))
    });
  }

  const openSubPortal = async () => {
    // helo
    if (appState.role == RoleTypes.FREE) {
      // if free then we redirect to sub page
      //navigate("/pricing");
      window.location.replace("/pricing")
    } else {
      dispatch(updateAppState({ loading: true }))
      openUserSubPortal()
    }
  }

  return (
    <BrowserRouter>
      <Box sx={{ display: 'flex' }}>

        {mobile ||
          <AppBar
            className="appbar"
            position="fixed"
            sx={{
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              ml: { sm: `${drawerWidth}px` },
            }}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>

              <div style={{ fontSize: '1.5rem', marginLeft: '0px' }} className="ArtiusText">Artius<span className="ArtiusText_dot">.</span></div>

            </Toolbar>
          </AppBar>

        }
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            {!open || <div className="ArtiusText">Artius<span className="ArtiusText_dot">.</span></div>}
            <IconButton onClick={handleDrawerToggle}>
              {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <List>

            {menuConfig.filter(z => z.show).map(x =>

              <NavLink key={x.linkName} to={x.route.to} style={({ isActive }) =>
                isActive ? activeStyle : {}}>

                <ListItem selected={false} disablePadding sx={{ display: 'block' }}>

                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,

                    }}
                    onPointerDown={() => { x.func && x.func() }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      {x.IconComponent}
                    </ListItemIcon>

                    <ListItemText primary={x.linkName} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>

                </ListItem>

              </NavLink>)}

            <hr className='subBoxHr'></hr>

            {open &&
              <div>
                {(appState.uid && appState.loggedIn) && <SubProgress></SubProgress>}
                <p className="caption">Artius v0.8 <br></br>2022 All Rights Reserved
                  <br></br>  <NavLink to={"/tnc"} > <span className="linkPurple">Terms And Conditions</span> </NavLink>
                  <p>Need help? Check out our <a className="linkOrange" href="https://blog.artius.studio/">Blog</a></p>
                </p>
              </div>}

          </List>
        </Drawer>

        {!appState.loading ||
          <div className="globalLoader center-element">
            <div className="center-element">
              <CircularProgress color="inherit" size={20} />
              <p></p>
             {appState.loadingMsg}
            </div>
          </div>
        }
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>



          <React.Suspense fallback={<div>Loading...</div>}>
            <Routes >
              <Route path="/projects/:projectId" element={(appState.emailVerified && appState.loggedIn) ? <Editor /> : <Landing />} />
              <Route path="/training/:projectId" element={(appState.emailVerified && appState.loggedIn) ? <TrainingEditor /> : <Landing />} />
              <Route path="/projects" element={(appState.emailVerified && appState.loggedIn) ? <Projects /> : <Landing />} />
              <Route path="/" element={(appState.emailVerified && appState.loggedIn) ? <Projects /> : <Landing />} />
              <Route path="/my-gallery" element={(appState.emailVerified && appState.loggedIn) ? <MyGallery /> : <Landing />} />
              <Route path="/training" element={(appState.emailVerified && appState.loggedIn) ? <Training /> : <Landing />} />
              <Route path="/training-auto/:autocreate" element={(appState.emailVerified && appState.loggedIn) ? <Training /> : <Landing />} />
              <Route path="/error" element={<ErrorPage />} />
              <Route path="/help" element={<HelpPage />} />
              <Route path="/tnc" element={<TNC />} />
              <Route path="/profile-pictures" element={(appState.emailVerified && appState.loggedIn) ? <Training /> : <CoprateImageLandingPage />} />
              <Route path="/pricing" element={(appState.emailVerified && appState.loggedIn) ? <Pricing /> : <Landing />} />
            </Routes>
          </React.Suspense>
          {appState.errorMsg && <Alert onClose={() => { dispatch(updateAppState({ errorMsg: "" })) }} color='error' sx={{ color: "red" }} className="errorMsg" severity="error">{appState.errorMsg}</Alert>}




        </Box>
      </Box>
    </BrowserRouter>
  );
}
export default MainPage

import React, { useEffect } from 'react'
import './training.scss'
import folderPng from '../../assets/folder.png'
import { App, DbName, ModelsArtius, ProjectArtius, ProjectId, ProjectType } from '../../models/shared_models';
import { useNavigate, useParams } from 'react-router-dom';
import TextRename from '../../components/text-rename/text-rename';
import { Alert, CircularProgress, Divider, Snackbar } from '@mui/material';
import folderCreatePng from '../../assets/folderCreate.png'
import { onSnapshot, collection, query, orderBy, doc, getDoc } from "firebase/firestore";
import { CreateUpdateProject, db, deleteProject, deleteTrainingProject } from '../../services/web.service';
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../app/states/appState';
import { EditorState, updateTrainingSettings } from '../../app/states/articleWatch';
import { makeid } from '../../services/util';
import { imageInitial,  stylesInitial, trainingInitial } from '../../models/configs';

function Training() {
  const navigate = useNavigate();
  const appState: App = useSelector((state: any) => state.appState.app as App);
  const [alertMsg, setAlertMsg] = React.useState<string>("");
  const [folders, setFolders] = React.useState<Array<ProjectArtius>>([]);
  const [folderLoading, setfolderLoading] = React.useState<boolean>(true);
  const dispatch = useDispatch()
  const projectDetails: ProjectArtius | null = useSelector((state: any) => (state.imageEditorState as EditorState).projectSettings);
  const { autocreate } = useParams();

  // component mounting and unmounting
  useEffect(() => {
    dispatch(updateTrainingSettings(null))
    // update all folders
    const imagesRef = collection(db, DbName.user, appState.uid, DbName.training);
    const q = query(imagesRef, orderBy("createdDate", "desc"));

    const unsub = onSnapshot(q, (querySnapshot) => {
      const tmpFolder: Array<ProjectArtius> = [];
      querySnapshot.forEach((doc) => {
        tmpFolder.push(doc.data() as ProjectArtius)
      });
      setFolders(tmpFolder)
      setfolderLoading(false);
      
    });

    // if this is autocreate, we auto create profile image template.
    if(autocreate) onCreateUpdateProject({...templates[1], ...{folderName:"My Pictures", projectId:makeid(5) as ProjectId}}, true)

    return () => {
      // Anything in here is fired on component unmount.
      unsub();
    }
  }, [])

  const templates: Array<ProjectArtius> = [
    {
      folderName: "Create Custom Model",
      projectId: makeid(5) as ProjectId,
      thumbnail: [],
      description: "",
      tags: [],
      createdDate: (new Date()).toISOString(),
      type: ProjectType.Dreambooth
    },
    {
      folderName: "Create Custom Corporate Headshots",
      projectId: makeid(5) as ProjectId,
      thumbnail: [],
      description: `Train your own images with Stable Diffusion. Upload 15-30 images of yourself in vairous different angles and poses. 
      Include closeups and wide shots too - include differnt wardrobe to guarantee best results. Click 'Start Training' to start creating your own custom model!`,
      tags: [],
      createdDate: (new Date()).toISOString(),
      type: ProjectType.Dreambooth,
      trainingSettings: {...trainingInitial,
        dateCreated : (new Date()).toISOString(),
        mUID : projectDetails?.projectId || '',
        displayName : 'My Model Name',
        inputs : {
          dreamBooth : "",
          files: [],
          instance_prompt: "photo of artius.JohnDoe",
          class_prompt: "photo of a person",
          width: 500,
          height: 500
        }
      },
      modelSettings:{...imageInitial.modelSettings, 
        style: {id:909}, 
        modelType: ModelsArtius.stableDiffusion,
        modelInputs: stylesInitial.find(x=>x.id == 909)?.modelInputs || imageInitial.modelSettings.modelInputs
      }
    },
    {
      folderName: "Create Custom Product Shots",
      projectId: makeid(5) as ProjectId,
      thumbnail: [],
      description: `Train your own images with Stable Diffusion. Upload a set of images, at least 5-10, of your product and hit "Start Training" to create a custom model that you can use.`,
      tags: [],
      createdDate: (new Date()).toISOString(),
      type: ProjectType.Dreambooth,
      trainingSettings: {...trainingInitial,
        dateCreated : (new Date()).toISOString(),
        mUID : projectDetails?.projectId || '',
        displayName : 'My Model Name',
        inputs : {
          dreamBooth : "",
          files: [],
          instance_prompt: "photo of artius.productName",
          class_prompt: "photo of woman wearing a hat",
          width: 500,
          height: 500
        }
      },
      modelSettings:{...imageInitial.modelSettings, 
        style: {id:909}, 
        modelType: ModelsArtius.stableDiffusion,
        modelInputs: stylesInitial.find(x=>x.id == 909)?.modelInputs || imageInitial.modelSettings.modelInputs
      }
    }
  ]

  const openEditor = (x: ProjectArtius) => {
    // go to project folder and pass model settings.
    navigate("/training/" + x.projectId);
    // dispatch(selectImageEditor({modelSettings: x.modelSettings ? x.modelSettings : null, imageDetails:null}))
    //updateModelSettingsImageEditorAll
  }

  const onCreateUpdateProject = (folder: ProjectArtius, autoRedirect = false) => {
    console.log("Create new Project", folder)
     dispatch(updateAppState({ loading: true }))
    //const docRef = await addDoc(collection(db, "user"), folder);
    CreateUpdateProject(appState.uid, folder, DbName.training).then(function () {
      setAlertMsg("")
      dispatch(updateAppState({ loading: false }))
      if(autoRedirect) navigate("/training/" + folder.projectId);
    }).catch((error) => {
      console.log("ERROR", error)
      dispatch(updateAppState({ loading: false }))
      setAlertMsg("Failed to create/update folder. Try refreshing the page and trying again.")
    });


  }


  const deleteProj = (project: ProjectArtius) => {
    dispatch(updateAppState({ loading: true }))
    deleteTrainingProject(project).then(function () {
      setAlertMsg("")
      dispatch(updateAppState({ loading: false }))
    }).catch((error) => {
      console.log("ERROR", error)
      dispatch(updateAppState({ loading: false }))
      setAlertMsg("Failed to Delete folder. Try refreshing the page and trying again.")
    });
  }
  

  //this function returns the JSX.
  return (
    <React.Fragment>

      <Snackbar anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }} open={!!alertMsg} autoHideDuration={6000} >
        <Alert onClose={() => setAlertMsg("")} severity="error" sx={{ width: '100%' }}>
          {alertMsg}
        </Alert>
      </Snackbar>

      <div className="headerBreacdrumb">
        AI Fine Tuning
      </div>
      <p>
        Train your own AI model with images of your choice.</p>

      {templates.map(x =>
        <div key={x.projectId} className="folder">
          <div onClick={(v) => onCreateUpdateProject({...x, ...{folderName:"Untitled Project", projectId:makeid(5) as ProjectId}})} key={x.projectId} className="folderImage" style={{ backgroundImage: 'url("' + folderCreatePng + '")' }}></div>
          <div className="folderImageCaption"> <TextRename disableEditing={true} text={x.folderName}></TextRename>  </div>
        </div>)}
      <div className="clearfix"></div>


      <Divider light >My Projects</Divider>

      {folderLoading && <p className="elemntCenter">
        <CircularProgress size={20} color="inherit" />
      </p>}

      {folders.map(x =>
        <div key={x.projectId} className="folder">
          <div onClick={(v) => openEditor(x)} key={x.projectId} className="folderImage" style={{ backgroundImage: 'url("' + folderPng + '")' }}></div>
          <div className="folderImageCaption"> <TextRename onDelete={()=>deleteProj(x)} onRename={(v) => onCreateUpdateProject({ ...x, folderName: v })} disableEditing={false} text={x.folderName}></TextRename>  </div>
        </div>)}
      <div className="clearfix"></div>

    </React.Fragment>
  );
}

export default Training


import React from 'react'
import './error.scss'



function ErrorPage() {
  //this function returns the JSX.
  return (
    <React.Fragment>
    
    <div className="headerBreacdrumb">
        An Error Occured
      </div>
    An error occured with this request, try again later.

    </React.Fragment>
  );
}

export default ErrorPage

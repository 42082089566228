import React from 'react'
import './help.scss'



function HelpPage() {
  //this function returns the JSX.
  return (
    <React.Fragment>

      <div className="headerBreacdrumb">
        The Masters at Work.
      </div>

      <p><iframe width="560" height="315" src="https://www.youtube.com/embed/UpN4Q9bJR0o" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe></p>


      <p><iframe width="560" height="315" src="https://www.youtube.com/embed/xq6lxsDkCZA" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe></p>



      <p><iframe width="560" height="315" src="https://www.youtube.com/embed/Oz_MPAuTvuk" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe></p>

 
      <p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/uxssPskkHSY" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>      </p>

    </React.Fragment>
  );
}

export default HelpPage

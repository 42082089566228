import { createSlice, current, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { defaultPermissions, dreamBoothSettingsInitial, imageInitial, inpaintingInitial, trainingInitial } from '../../models/configs';
import { ArtiusModelTraining, ArtiusPermissions, DbName, EditorSettings, ImageArtius, ImageDetails, ModelInputs,  ModelSettings, ProjectArtius, ProjectType } from '../../models/shared_models'
import { doc, getDoc } from "firebase/firestore";
import { db } from '../../services/web.service';
import merge from 'lodash/merge';

// Define a type for the slice state
export interface EditorState {
  images: ImageArtius[];
  currentImage: ImageArtius;
  editorSettings: EditorSettings;
  projectSettings: ProjectArtius | null;
  trainingSettings: ArtiusModelTraining | null;
  permissionMatrix: ArtiusPermissions,
  useCustomModels: ArtiusModelTraining[]
}

// Define the initial state using that type
export const initialEditorState: EditorState = {
  images: [],
  projectSettings:null,
  editorSettings: {
    loading: false,
    inpaintingSettings : inpaintingInitial,
    zoom:0.9,
    concurrentGenerations:0,
    canvasPos : {x:10,y:80},
    showNegativePromnt: false,
  },
  currentImage:imageInitial,
  permissionMatrix: defaultPermissions,
  trainingSettings: null,
  useCustomModels: [] // stores all custom fine tune models.
} as EditorState

// get project data and reset canvas
export const getProjectData =  createAsyncThunk("imageEditorState/getProject", async (data:{uid:string, projectId:string})=>{
  const docRef = doc(db, DbName.user, data.uid, DbName.project, data.projectId as string);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log("Get Project Data data:", docSnap.data());
    return docSnap.data() as ProjectArtius
  } else {
    // doc.data() will be undefined in this case
    return null;
  }
  
})

type TrainingDataThunk = {projectDetails:ProjectArtius, trainingSettings:ArtiusModelTraining} | null;
//get training project and training settings
export const getTrainingData =  createAsyncThunk("imageEditorState/getTrainingData", async (data:{uid:string, projectId:string}) : Promise<TrainingDataThunk> =>{
  // get project data
  const docRef = doc(db, DbName.user, data.uid, DbName.training, data.projectId as string);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    // get training Data
    const projectDetails = docSnap.data() as ProjectArtius;

    // get traning data
    const docTraining = doc(db, DbName.user, data.uid, DbName.training, data.projectId as string, DbName.trainingSettings, data.projectId);
    const docTrainingSnap = await getDoc(docTraining);
    let trainingSettings = docTrainingSnap?.data() as ArtiusModelTraining | null;

    // if not training data we need to create defaults
    if(!trainingSettings){
      // or else create default training data
      const defaultTraining: ArtiusModelTraining = JSON.parse(JSON.stringify(trainingInitial));
      defaultTraining.dateCreated = (new Date()).toISOString();
      defaultTraining.mUID = projectDetails.projectId;
      defaultTraining.displayName = projectDetails?.folderName || '';
       // If there is traning input presets we will use that.
       if (projectDetails
        && projectDetails.type == ProjectType.Dreambooth
        && projectDetails?.trainingSettings?.inputs) {
          defaultTraining.inputs = projectDetails?.trainingSettings?.inputs;
      } else if (projectDetails && projectDetails.type == ProjectType.Dreambooth) {
        defaultTraining.inputs = dreamBoothSettingsInitial;
      }
      trainingSettings = defaultTraining;
    }

    return {projectDetails, trainingSettings}
  } else {
    // doc.data() will be undefined in this case
    return null;
  }
  
})


export const imageEditorSlice = createSlice({
  name: 'imageEditorState',
  initialState: initialEditorState,
  reducers: {
    addImageEditor: (state: EditorState, action: PayloadAction<ImageArtius>) => {

      //add a new entry.
      state.images.unshift(action.payload)

    },
    replaceAllImageEditor: (state: EditorState, action: PayloadAction<Array<ImageArtius>>) => {
      state.images = action.payload;
    },
    selectImageEditor: (state: EditorState, action: PayloadAction<ImageArtius>) => {
      state.currentImage = action.payload;
    },

    updateModelSettingsImageEditorNew: (state: EditorState, action: PayloadAction<any>) => {
     // console.log(current(state.currentImage.modelSettings))
     const currentData : ModelSettings = current(state.currentImage.modelSettings)
      state.currentImage.modelSettings =  merge({}, currentData, action.payload)
    },
    updateTrainingSettings: (state: EditorState, action: PayloadAction<any>) => {
      // console.log(current(state.currentImage.modelSettings))
      if (state.trainingSettings && action.payload) {
        // if training settings exist we will do a merge
        const currentData: ArtiusModelTraining = current(state.trainingSettings)
        state.trainingSettings = merge({}, currentData, action.payload)
      } else {
        // else do a full replace.
        state.trainingSettings = action.payload
      }
    },
    replaceTrainingInputs: (state: EditorState, action: PayloadAction<any>) => {
      if(state.trainingSettings) state.trainingSettings.inputs = action.payload
    },
    updateEditorSettings: (state: EditorState, action: PayloadAction<{label:string, value:any}>) => {
      state.editorSettings = { ...state.editorSettings, [action.payload.label]: action.payload.value }
    },
    updateEditorSettingsNew: (state: EditorState, action: PayloadAction<any>) => {
      state.editorSettings = { ...state.editorSettings, ...action.payload }
    },
    updateAppPermissionsState: (state: EditorState, action: PayloadAction<ArtiusPermissions>) => {
      state.permissionMatrix = { ...state.permissionMatrix, ...action.payload }
    },
    updateModelInputsState: (state: EditorState, action: PayloadAction<ModelInputs>) => {
      state.currentImage.modelSettings.modelInputs = { ...state.currentImage.modelSettings.modelInputs, ...action.payload } as ModelInputs
    },
    updateInpaintingSettings: (state: EditorState, action: PayloadAction<any>) => {
      state.editorSettings.inpaintingSettings = { ...state.editorSettings.inpaintingSettings, ...action.payload}
    },
    updateCustomModels: (state: EditorState, action: PayloadAction<ArtiusModelTraining[]>) => {
      // updates custom fine tune models
      state.useCustomModels = action.payload;
    },
    updateImageDetailsEditor: (state: EditorState, action: PayloadAction<ImageDetails | null>) => {
      state.currentImage.imageDetails = action.payload
    },
    updateProjectDetailsEditor: (state: EditorState, action: PayloadAction<ProjectArtius | null>) => {
      state.projectSettings = action.payload;
    },
    replaceModelInputs: (state: EditorState, action: PayloadAction<ModelInputs | null>) => {
      state.currentImage.modelSettings.modelInputs = action.payload;
    },
    updateModelSettings: (state: EditorState, action: PayloadAction<ModelSettings>) => {
      state.currentImage.modelSettings = action.payload;
    },
  },
  extraReducers: builder=> {
    builder.addCase(getProjectData.pending, (state, actions) =>{
      // update state if getting project is still pending
      // we will reset state
      state.images = [];
      state.projectSettings = null;
      state.currentImage.imageDetails = null;
      state.currentImage.modelSettings = initialEditorState.currentImage.modelSettings
    }).addCase(getProjectData.fulfilled, (state: EditorState, action: PayloadAction<ProjectArtius | null>) =>{
      // If fufilled we will update project settings from data from the server..
      if(action?.payload) state.projectSettings = action.payload;
      if(action?.payload?.modelSettings) state.currentImage.modelSettings = action.payload.modelSettings
      
    }).addCase(getTrainingData.pending, (state: EditorState, action: PayloadAction<any>) =>{
      // If pending we will reset all data.
      state.projectSettings = null;
      state.trainingSettings = null;
    }).addCase(getTrainingData.fulfilled, (state: EditorState, action: PayloadAction<TrainingDataThunk>) =>{
      // If fulfilled we will update store.
      if(action?.payload?.projectDetails) state.projectSettings = action.payload.projectDetails;
      if(action?.payload?.trainingSettings) state.trainingSettings  = action.payload.trainingSettings;
    })
  }
})

// Action creators are generated for each case reducer function
export const { addImageEditor, 
  replaceTrainingInputs, 
  updateTrainingSettings, 
  updateModelSettings, 
  updateModelInputsState, 
  updateInpaintingSettings,
  updateEditorSettingsNew, 
  replaceModelInputs,  
  updateModelSettingsImageEditorNew, 
  replaceAllImageEditor, 
  updateImageDetailsEditor, 
  updateProjectDetailsEditor, 
  updateEditorSettings, 
  updateAppPermissionsState,
  selectImageEditor, 
  updateCustomModels } = imageEditorSlice.actions

export default imageEditorSlice.reducer

// Other code such as selectors can use the imported `RootState` type
export const selectAllImages = (state: EditorState) => state.images;
export const getCurrentImage = (state: EditorState) => state.currentImage;
export const getImage = (state: EditorState) => state.images;
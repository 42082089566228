import React from 'react'
import styles from './accordion.module.scss'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface Props {
  header: string;
  children: React.ReactNode;
  height?:number
  open?:boolean
}


function Accordion(props: Props) {
  //this function returns the JSX.
  const [open, setOpen] = React.useState<boolean>(props.open == undefined ? true : props.open);

  const expandToggle = () =>{
    setOpen(!open)
  }

  return (
    <React.Fragment>
     <div className={styles.settingHeader} onClick={expandToggle}>{props.header} 

      <span ><KeyboardArrowDownIcon className={styles.arrowIconStyle} style={{transform: !open ? "rotateZ(360deg)" : "rotateZ(180deg)"}}  /></span> 
   

       </div>
     <div className={styles.content} style={!open ? {height:"0rem"} : {height: props.height+"rem" ? props.height :"auto"}}>{props.children}</div>
    </React.Fragment>
  );
}

export default Accordion

import React, { useEffect } from 'react'
import { App, ArtiusModelTraining,  DreamBoothSettings, ImageArtius, ImageFiles, StatusTypes } from '../../../../models/shared_models';
import './multiImageUpload.scss'
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteFile, uploadTrainingImages } from '../../../../services/web.service';
import { useDispatch, useSelector } from 'react-redux';
import { EditorState, replaceTrainingInputs, updateTrainingSettings } from '../../../../app/states/articleWatch';
import ImageIcon from '@mui/icons-material/Image';
import { updateAppState } from '../../../../app/states/appState';
import { Button } from '@mui/material';
function MultiImageUpload() {
  //this function returns the JSX.
  const appState: App = useSelector((state: any) => state.appState.app as App);
  const trainingSettings: ArtiusModelTraining | null = useSelector((state: any) => (state.imageEditorState as EditorState).trainingSettings);
  


  const dispatch = useDispatch()

  // on mount
  useEffect(() => {


    // destroy fabric on unmount
    return () => {
      /*  drawCanvas.current.dispose();
       drawCanvas.current = null; */

    };
  }, []);


  const dowloadImage = (image: ImageArtius) => {
    if (image.imageDetails) {
      const link = document.createElement('a');
      link.href = image.imageDetails?.imgUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const deleteImageFile = async (image: ImageFiles) => {

    // delete file
    dispatch(updateAppState({ loading: true }))
    deleteFile(image.fireRefrence).then(() => {
      // File deleted successfully
      deleteImageFromFilestore(image)

      dispatch(updateAppState({ loading: false }))
    }).catch((error: any) => {
      // Uh-oh, an error occurred!
      deleteImageFromFilestore(image)
      dispatch(updateAppState({ errorMsg: "File Deletion failed.", loading: false }))
    });

  }

  const deleteImageFromFilestore = (image: ImageFiles) =>{
    ///
    const newTrainingSettings: ArtiusModelTraining = JSON.parse(JSON.stringify(trainingSettings));
    (newTrainingSettings.inputs as DreamBoothSettings).files = (newTrainingSettings.inputs as DreamBoothSettings).files.filter(x => x.fileName != image.fileName)
    dispatch(replaceTrainingInputs(newTrainingSettings.inputs))
  }

  // when file is uploaded.
  const onFileChange = (evt:any) =>{

    if(!trainingSettings) return null;

    dispatch(updateAppState({ loading: true }))
    //when fil upload
    uploadTrainingImages(evt, trainingSettings, 30, appState.uid ).then(x=>{
      // when all images are uplaoded.
      console.log("Files uploaded")
      dispatch(updateTrainingSettings({ inputs: { files: x.files } }))

    }).catch(x=>{
      // uploading failed.
      dispatch(updateAppState({ errorMsg: x.error }))
    }).finally(()=>{
      dispatch(updateAppState({ loading: false }))
    })
  }

  return (
    <React.Fragment>


      {(trainingSettings && (trainingSettings?.inputs as DreamBoothSettings)?.files.length == 0) &&
        <div>You have not uploaded any images! Click on Upload Images to get started!
         <p></p>
          <Button onChange={onFileChange} startIcon={<ImageIcon />} className="artiusButtonOutline btn empBtn" component="label" variant="outlined">
                    Upload Images
                    <input hidden accept="image/*" type="file" multiple />
                  </Button>
        </div>
      }

    
      <p></p>
      <div className="galerContainer">
        {trainingSettings && (trainingSettings?.inputs as DreamBoothSettings)?.files.map(x =>
          <div key={x.fileName} className="galleryImage">
            <img className="galleryimg" src={x.url} width={"200rem"}></img>
            <div className="spaceBreak"></div>
            <div style={{ float: "right", cursor: "pointer" }}> <DeleteIcon onClick={(v) => deleteImageFile(x)}></DeleteIcon></div>
          </div>
        )}
      </div>

    </React.Fragment>
  );
}

export default MultiImageUpload

import { configureStore} from '@reduxjs/toolkit'
import  appSlice  from './states/appState'
import imageEditorSlice , {updateAppPermissionsState} from './states/articleWatch'
import { ArtiusPermissions, ImageArtius, ModelsArtius } from '../models/shared_models';

/// Update Application Permissions based on state changes
// THis custom middle ware should only change the permission matrix.
const updateAppPermissions = (store:any) => (next:any) => (action:any) => {
  
  // Call the next middleware or the reducer
  const result = next(action);

  const permissionMatrix : ArtiusPermissions = store?.getState()?.imageEditorState?.permissionMatrix;
  const currentImage : ImageArtius = store?.getState()?.imageEditorState?.currentImage;
  //console.log("PERMISSION MATRIX",currentImage )

  /// FOR ADVERTSING MODEL PERMISSIONS// 
  if(currentImage?.modelSettings?.modelVersionId=="SDAdImage"
  && currentImage?.modelSettings?.modelType==ModelsArtius.stableDiffusion
  && permissionMatrix?.hasAiImageCreation == true){
    /// If model version is using advertising BG changer, we will hide all image creation buttons 
    // this feature only requres edit button 
    console.log("PERMISSION MATRIX",currentImage )
    store.dispatch(updateAppPermissionsState({hasAiImageCreation:false}));
  }else if(currentImage?.modelSettings?.modelVersionId!="SDAdImage"
    && permissionMatrix?.hasAiImageCreation == false
    ){
    // reset permission if model verion is changed
    console.log("PERMISSION MATRIX",currentImage )
    store.dispatch(updateAppPermissionsState({hasAiImageCreation:true}));
  }

  return result;
};



export default configureStore({
  reducer: {imageEditorState: imageEditorSlice, appState: appSlice},
  middleware:  (getDefaultMiddleware) => getDefaultMiddleware().concat(updateAppPermissions),
})


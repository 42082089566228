import { CircularProgress, ClickAwayListener, IconButton, Input, InputAdornment, TextField, Tooltip } from '@mui/material';
import React, { useState } from 'react'
import styles from './text-rename.module.scss'
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';

interface Props {
  text: string;
  textStyle?: React.CSSProperties;
  editable?: boolean;
  disableEditing?: boolean;
  disableDelete?: boolean;
  onRename?: (name: string) => void;
  onDelete?: () => void;
}


function TextRename(props: Props) {
  //this function returns the JSX.
  const [editable, setEditable] = useState<boolean>(props.editable || false);
  const [loading, setLoading] = useState<boolean>(false);
  const [renameText, setRenameText] = useState<string>("");

  const editText = (text: string) => {
    if (!props.disableEditing) {
      setEditable(true)
    }
  }

  const updateText = () => {
    if (renameText) {
      //setLoading(true)
      if (props.onRename) { props.onRename(renameText) }
      setEditable(false)
    }
  }

  const deleteProj = () => {
    if (props.onDelete) props.onDelete() 
  }

  const handleClickAway = () => {
    setEditable(false)
  }

  return (
    <React.Fragment>

      {!editable ?

        !props.disableEditing ? <Tooltip title="Rename" placement="bottom">
          {/* If this folder is editable we will show this button indtead. */}
          <span className={styles.editableText} style={props.textStyle} onClick={(v) => editText(props.text)}>{props.text}</span>
        </Tooltip>
          :
          props.disableEditing &&
          <span style={props.textStyle} onClick={(v) => editText(props.text)}>{props.text}</span>

        :
        <ClickAwayListener onClickAway={handleClickAway}>
          <Input onChange={x => {setRenameText(x.target.value)}} value={renameText} className={styles.editableTextInput} id="standard-basic" style={props.textStyle} placeholder={props.text} endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="Rename Text"
                onClick={updateText}
                edge="end"
              >
                {loading ? <CircularProgress color="inherit" size={20} /> : <DoneIcon />}
              </IconButton>

              { (props.disableDelete) || <IconButton
                aria-label="Delete Project"
                onClick={deleteProj}
                edge="end"
              >
                <DeleteIcon />
              </IconButton>}
              
            </InputAdornment>
          } />
        </ClickAwayListener>
      }

    </React.Fragment>
  );
}

export default TextRename

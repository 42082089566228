import React, { Fragment } from 'react';
import '../../index';
import styled from 'styled-components';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

import LoginBox from '../../components/loginBox/loginBox';
import { stylevars } from '../../stylevars'
import profileImage from '../../assets/profile.jpg';
import profileImage1 from '../../assets/tech.png';
import profileImage2 from '../../assets/dating.jpg';
import profileImage3 from '../../assets/woemn_cartoon.jpg';
import { Rating } from '@mui/material';

import realImage from '../../assets/real.jpg';
import fakeimage from '../../assets/fake.jpg';

const Title = styled.div`
  //  width: 25rem;
    font-size: xx-large;
    color: #FFFFFF;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
    margin-left: $margin-1x;

    &.centered{
        text-align: center;
    }

    &.short{
        max-width: 25rem;
    }

    &.topGap{
        margin-top: ${stylevars['margin-2x']};
    }

    &.btmGap{
        margin-bottom: ${stylevars['margin-3x']};
    }
`
const Highlight = styled.b`
    color: ${stylevars['artius-orange']};
    font-weight: ${stylevars['font-bold']};
`

const BulletPoints = styled.ul`
   // width: 25rem;
    font-size: $font-size-1;
    color: $purple-text;
    padding-inline-start:1rem;

    li{
        margin-bottom: ${stylevars['margin-1x']};
    }
`
const Iframe = styled.iframe`
    border: 0px none;
    width: 100%;
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25));
`

const CenterContainer = styled.div`
    margin-top: 5rem;
`

const InnerContainer = styled.div`
   // width: 25rem;
   max-width: 35rem;
`
const ProfileImage = styled.img`
    width: 13rem;
    border: solid 1px ${stylevars['purple-stroke']};
   
    
`
const Banner = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: ${stylevars['margin-3x']};

    @media only screen and (max-width: 600px) {
        flex-direction: column-reverse;
        align-items: center;
    }
`
const ReviewBox = styled.div`
    margin-bottom: ${stylevars['margin-3x']};
    
    align-items: center;
    /* justify-content: center; */
    display: flex;
    flex-direction: column;
    
    .caption{
        max-width: 15rem;
        font-size:  ${stylevars['font-size-1-5x']};
        color:white;
    }

    .job{
        font-style: italic;
    }
`

const FAQ  = styled.p`
    color: white;
`

const ReviewSection = styled.div`
    display: flex;
     flex-direction: row;
     gap:1rem;
     text-align: center;
     flex-wrap: wrap;
     @media only screen and (max-width: 600px) {
        flex-direction: column;
    }
`


  const properties = {
    duration: 1500,
    autoplay: true,
    transitionDuration: 500,
    arrows: false,
    infinite: true,
    easing: "ease",
  };

function CoprateImageLandingPage() {

    return (
        <Fragment>
            <CenterContainer className={'elemntCenter'} >
                <InnerContainer>

                    <Banner>
                        <div>
                            <Title className={'short'}> Professional AI Generated Headshots</Title>
                            <BulletPoints>
                                <li>Create <Highlight>Unlimited</Highlight> Professional Quality Corporate Headshots with AI.</li>
                                <li>Use Only <Highlight>Your Existing Images</Highlight>. (15-25 images recommended)</li>
                                <li>Includes <Highlight>Unlimited Styles</Highlight>, Realistic or Stylised.</li>
                                <li><Highlight>FREE Trial</Highlight> Available.</li>
                            </BulletPoints>
                        </div>
                        <div>

                            <div className="slide-container" style={{width:"13rem"}}>
                                <Slide  {...properties}>
                                <ProfileImage src={realImage} />
                                <ProfileImage src={fakeimage} />
                                </Slide>
                            </div>


                        </div>
                       
                    </Banner>

                    <p><Iframe height="315" src="https://www.youtube.com/embed/xq6lxsDkCZA" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></Iframe></p>

                    <Title className={'centered topGap btmGap'}> Signup for Free </Title>

                    <LoginBox ></LoginBox>
                    <Title className={'centered topGap btmGap'}> Reviews </Title>

                    <ReviewSection>
                        <ReviewBox>
                            <ProfileImage src={profileImage} />
                            <div>Jane Williams<br/> <span className="job">Marketing Executive</span></div>
                            <Rating name="read-only" value={3} readOnly />
                            <div className="caption">&quot;I have been using this app for my <Highlight>sales material</Highlight>, and it has completely transformed the way I present myself. The professional quality of the corporate profile pictures has elevated my brand image and helped me make a <Highlight>lasting impression</Highlight> on potential clients!&quot;</div>
                        </ReviewBox>

                        <ReviewBox>
                            <ProfileImage src={profileImage1} />
                            <div>Mark Johnson<br/> <span className="job">Programmer</span></div>
                            <Rating name="read-only" value={5} readOnly />
                            <div className="caption">&quot;Using this app for my <Highlight>LinkedIn profile picture</Highlight> was the best decision I made. The AI-powered features made my photo look <Highlight>polished and professional</Highlight>, instantly capturing attention!&quot;</div>
                        </ReviewBox>

                        <ReviewBox>
                            <ProfileImage src={profileImage2} />
                            <div>Emily Parker<br/> <span className="job">Architect</span></div>
                            <Rating name="read-only" value={4} readOnly />
                            <div className="caption">&quot;I recently used this app to create my <Highlight>dating profile picture</Highlight>, and the results were outstanding. The apps ability to enhance the photo while maintaining a natural look gave me the confidence to put my best foot forward!&quot;</div>
                        </ReviewBox>

                        <ReviewBox>
                            <ProfileImage src={profileImage3} />
                            <div>Rachel Thompson<br/> <span className="job">Influencer</span></div>
                            <Rating name="read-only" value={4} readOnly />
                            <div className="caption">&quot;Im a passionate <Highlight>online RPG gamer</Highlight>, and having a captivating avatar is crucial for my gaming experience. This app helped me create a <Highlight>stunning profile picture</Highlight> that perfectly represents my in-game character!&quot;</div>
                        </ReviewBox>

                    </ReviewSection>

                    <Title className={'centered btmGap'}> FAQ </Title>

                    <FAQ>How can i get the best results?</FAQ>
                    For best results, try uploading at least 15-25 images of your self in various poses and environments. Include closeups and full body shots so the AI can learn what you look like in different angles and settings.

                    <FAQ>Can i edit my images?</FAQ>
                    Yes Artius provides a range of tools for your to edit and improve your images with AI.

                    <FAQ>Images are coming our bad, what should i do?</FAQ>
                    No worries, our friendly support team is here to help. Contact us via discord or email and we will walk you through the whole way.

                    <FAQ>What type of styles do you offer?</FAQ>
                    You can use our ready made style presets or write your own! Artius provides the most customization compared to any AI product in the market. Do you want to be on a private jet? Or Shake hands with Obama? Anything is possible!                </InnerContainer>
            </CenterContainer>

        </Fragment>
    );

}

export default CoprateImageLandingPage


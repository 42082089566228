export const stylevars = {
    "screen-mobile": "600px",
    "bg-purple": "#23253C",
    "purple-secondary": "#343356",
    "purple-menu": "#272742",
    "purple-stroke": "#4A4A80",
    "purple-text": "#737FCE",
    "warning": "#FF4040",
    "artius-pink": "#DD0DFF",
    "artius-orange": "#FC9846",
    "artius-green": "#67F634",
    "artius-pink-gradient": "#E30DFD",
    "artius-dark-pink-gradient": "#8700F9",
    "artius-orange-gradient": "#FC9D39",
    "artius-dark-orange-gradient": "#FE72A2",
    "artius-purple-gradient": "#363559",
    "artius-dark-purple-gradient": "#2F2F4E",
    "font-size-05x": "0.8rem",
    "font-size-1x": "1rem",
    "font-size-1-5x": "1.25rem",
    "font-size-2x": "1.5rem",
    "font-thin": 200,
    "font-regular": 400,
    "font-bold": 700,
    "padding-1x": "1rem",
    "padding-05x": "0.5rem",
    "margin-1x": "1rem",
    "margin-2x": "2rem",
    "margin-3x": "3rem",
    "margin-05x": "0.5rem"
  };
import { Alert, Button, TextField } from '@mui/material';
import React from 'react'
import './loginBox.scss'
import * as Yup from "yup";
import { useFormik } from 'formik';
import { createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../../services/web.service';
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../app/states/appState';
import { App } from '../../models/shared_models';
import { getAnalytics, logEvent } from "firebase/analytics";


import {
  NavLink, useNavigate,
} from "react-router-dom";
enum LoginPage {
  SIGNUP = "signup",
  LOGIN = "login",
}

interface props{
  route ? : string;
}

function LoginBox( props : props) {
  //this function returns the JSX.
  const appState: App = useSelector((state: any) => state.appState.app as App);
  const analytics = getAnalytics();
  const [page, setPage] = React.useState<LoginPage>(appState.loggedIn ? LoginPage.LOGIN : LoginPage.SIGNUP);
  const [alertMsg, setAlertMsg] = React.useState<string>("");
  const [sucessMsg, setSucessMsg] = React.useState<string>("");
  const navigate = useNavigate();
  const dispatch = useDispatch()


  const validationSchema = Yup.object({
    email: Yup.string().email().required(),
    password: Yup.string().required().min(6),
    retrypassword: Yup.string().required().min(6).oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });


  /// FORM validation
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      retrypassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      //alert(JSON.stringify(values, null, 2));
      dispatch(updateAppState({ loading: true }))
      setAlertMsg("")
      createUserWithEmailAndPassword(auth, values.email, values.password)
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          console.log(userCredential)


          sendEmailVerification(user)
            .then(() => {
              // Email verification sent!
              // ...
              dispatch(updateAppState({ loading: false }))


              setSucessMsg("Registration Successful! Check Your Email For the Confirmation Email")
              logEvent(analytics, 'sign_up', { name: 'sign_up'});
            }).catch((error) => {
              setAlertMsg("Failed to send confirmation Email. Please contact contact@artius.studio for help.")
              dispatch(updateAppState({ loading: false }))
            });

          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(error)
          setAlertMsg("Failed to Signup. Account might already exist.")
          dispatch(updateAppState({ loading: false }))
          // ..
        });

    },
  });

  const validationSchema2 = Yup.object({
    email: Yup.string().email().required(),
    password: Yup.string().required().min(6),
  });

  const formik2 = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema2,
    onSubmit: (values) => {
      //alert(JSON.stringify(values, null, 2));
      dispatch(updateAppState({ loading: true }))
      setAlertMsg("")
      signInWithEmailAndPassword(auth, values.email, values.password)
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          if(props.route) navigate(props.route);
          // ...
          //dispatch(updateAppState({ loading:false}))
        })
        .catch((error) => {
          dispatch(updateAppState({ loading: false }))
          setAlertMsg("Failed to Login.")
        });
    },
  });


  return (
    <React.Fragment>

      <div className="loginBox">
        <div className="headerBreacdrumb center"> <span onClick={() => setPage(LoginPage.SIGNUP)} className={"clickable " + (page == LoginPage.SIGNUP ? "active" : "")}>Signup</span> | <span className={"clickable " + (page == LoginPage.LOGIN ? "active" : "")} onClick={() => setPage(LoginPage.LOGIN)} >Login</span></div>
        {sucessMsg && <Alert severity="success">{sucessMsg}</Alert>}
        {(alertMsg && !sucessMsg) && <Alert severity="error">{alertMsg}</Alert>}
        {(!appState.emailVerified && appState.loggedIn && !alertMsg && !sucessMsg) && <Alert severity="error">Email not verified. Please check your inbox or Spam folder. If you need help please contact us at <a href="mailto:contact@artius.studio">contact@artius.studio</a> or use our <a  href="https://discord.gg/HKMmuRGf">discord</a></Alert>}

        {(page == LoginPage.SIGNUP && !appState.loggedIn) &&
          <form onSubmit={formik.handleSubmit}>
            <p></p>
            <TextField fullWidth label="Email" id="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <p></p>
            <TextField type="password" fullWidth label="Password" id="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <p></p>
            <TextField type="password" fullWidth label="Retry Password" id="retrypassword"
              value={formik.values.retrypassword}
              onChange={formik.handleChange}
              error={formik.touched.retrypassword && Boolean(formik.errors.retrypassword)}
              helperText={formik.touched.retrypassword && formik.errors.retrypassword}
            />

            <p className="elemntCenter">
              <Button type="submit" className="orangeButton" variant="contained">Sign Up</Button>
            </p>
            <span className="elemntCenter">
              <div>               By Signing Up You agree to our <NavLink to={"/tnc"} > <span className="linkPurple">Terms And Conditions</span></NavLink>
              </div>
            </span>
          </form>
        }


        {(page == LoginPage.LOGIN && !appState.loggedIn) &&
          <form onSubmit={formik2.handleSubmit}>
            <p></p>
            <TextField fullWidth label="Email" id="email"
              value={formik2.values.email}
              onChange={formik2.handleChange}
              error={formik2.touched.email && Boolean(formik2.errors.email)}
              helperText={formik2.touched.email && formik2.errors.email}
            />
            <p></p>
            <TextField type="password" fullWidth label="Password" id="password"
              value={formik2.values.password}
              onChange={formik2.handleChange}
              error={formik2.touched.password && Boolean(formik2.errors.password)}
              helperText={formik2.touched.password && formik2.errors.password}
            />
            <p></p>

            <p className="elemntCenter">
              <Button type="submit" className="orangeButton" variant="contained">Login</Button>
            </p>
          </form>
        }

      </div>

    </React.Fragment>
  );

}
export default LoginBox

// Helper for generating Opaque types.
type Opaque<T, K> = T & { __opaque__: K };

//Important Custom types.
export type JobId = Opaque<string, "JobId">;
export type ProjectId = Opaque<string, "ProjectId">;
export type UID = Opaque<string, "UID">;


// App state
export interface AppState {
    app: App;
}

export interface App {
    loading: boolean;
    emailVerified: boolean;
    loggedIn: boolean;
    email: string;
    uid: UID;
    role: RoleTypes | null;
    errorMsg: string;
    requests: number;
    appConfig: AppConfigs;
    loadingMsg : string;
}

export enum AppConfigs{
    PROFILE_PICS = "profilepics",
    DEFAULT = "default",
}


// Each Artius image contains these details
export interface ImageArtius {
    imageDetails: ImageDetails | null; // when a image is completed, this will  have data
    modelSettings: ModelSettings // model settings is the input parameters.
}


/// IMAGE EDITOR MODELS ////
// When image is genrated this are the details (genration response)
export interface ImageDetails {
    imgUrl: string; // the google image URL
    imageId: string; // to identify the genrated image, usually set by the image genrator.
    width: number;
    height: number;
    completedTime: string;
    totalTime: number;
    sourceUrl:string; // source image URL - from the image generator.
    errMsg?: string;
}

export interface EditorSettings {
    loading: boolean;
    inpaintingSettings: InpaintingWidget; // editor settings for inpainting.
    zoom:number;
    canvasPos : xyPos;
    concurrentGenerations:number;
    showNegativePromnt:boolean;
}


// for image genration request.
export interface ModelSettings {
    modelType: ModelsArtius;
    jobId: JobId | null; // job ID is a unique identifyer to identify this image. This is genrated by the system.
    refJobId?: JobId | null;   // Ref id is is set to the previous JObId this image genrated form, if its the first image it will set as null
    imageId?: string, // this is to identify the job and image. Usually genrated by the image genrator. Image Id much match ImageDetails.imageId
    // Display dimentions are usually used for display, hwoever we use actual dimiensions to squeeze image.
    width: number; // dimension of image.
    height: number; // dimension of image.
    displayWidth?: number; // dimension of image for display purpose only. Not used in processing.
    displayHeight?: number; // dimension of image for display purpose. Not used in processing.
    aspectRatioWidth: number;
    aspectRatioheight: number;
    imgPrompt: string; // text promnt for this image
    status: StatusTypes; // status of processing this image.
    // attachements?: UploadedFile[]; // **Deprecated** for uploading file.
    startTime?: string;
    uid: UID | null;  // users unique ID
    username?: string;  // username for credits
    modelInputs?: ModelInputs;  // input parameters for differnt image models
    inpainting?: InPainting;  //  to hold inpainitng data promnt only
    style?: StyleSetting; // syle types usually for image promnt augmentation/
    useSeed:boolean; 
    project?: ProjectArtius | null; // the project this image belongs too
    error?: string
    modelVersionId?:string | null; // verionId from modelVersion this is the submodel. *only avliable for stable diffusion model, this is the mUID from ArtiusModelTraining.
}

/// Permission matrix for Artius
export interface ArtiusPermissions {
    hasAiImageCreation:boolean;
}


// data structure for training inputs/ datasets/
export interface ArtiusTrainingInputs{
    images? : string[]
}

export interface StyleTypes{
    name:string;
    id:number;
    appendPromnt:string;
    preAppendPromnt:string;
    cat?: Array<number>;
    image?: string;
    modelType: ModelsArtius;
    examplePromnt?: string;
    modelInputs?: ModelInputs;
    negative_prompt?: string;
    modelSettings?: ModelSettings;
    autocomplete?: StyleAutoComplete[];
}

export interface StyleAutoComplete{
    value : string;
}


export interface StyleSetting{
    id:number;
}

export interface InPainting{
  //  enableInpainting: boolean;  // is inpainting enabled or not?
   // inpaintingRef?: JobId | null; // if this exists, then that means there is inpaiinting canvas data saved locally or firestore.
    promnt?:string; // speclized promnt just for inpainitng.
}

export interface Seeding{
    useSeed: true;
    seed?: number | null;
}

export type ModelInputs = StableDiffusionSettings | DallESettings | StableDiffusionWebSettings | null;

export interface StableDiffusionSettings {
    stableDiffusion: ModelsArtius.stableDiffusion;
    prompt_strength?: number;
    num_outputs?: number;
    num_inference_steps?: number;
    guidance_scale?: number;
    seed?: number;
    negative_prompt?: string;
}

export interface StableDiffusionWebSettings {
    stableDiffusionWeb: ModelsArtius.stableDiffusionWeb;
    prompt_strength?: number; //cfg_scale
    num_outputs?: number;
    num_inference_steps?: number; //steps
    seed?: number;
    steps?: string;
    negative_prompt?:string;
    style_preset:string;
}

export interface DallESettings {
    dalle: ModelsArtius.dalle;
    literalness: number;
    awesomeness: number;
    negative_prompt: string;
    
}

export interface SubscriptionDetails{
    price:number;
    plan: RoleTypes;
    maxRequest: number;
    name:string;
}

export enum ModelsArtius {
    stableDiffusion = "stableDiffusion",
    stableDiffusionWeb = "stableDiffusionWeb",
    discoDiffusion = "discoDiffusion",
    midjourney = "midjourney",
    dalle = "dalle",
}

export enum StatusTypes {
    NONE = "none",
    PENDING = "pending",
    COMPLETED = "completed",
    FAILED = "failed",
    DELETE = "delete"
}

export interface UploadedFile {
    fileName: string;
}

export interface InpaintingWidget {
    toolType: InpaintingToolTypes;
    brushSize: number;
}

export enum InpaintingToolTypes {
    BRUSH = "brush",
    ERASER = "eraser",
    SHAPES = "shapes",
    MOVE = "move",
    PROMNT = "promnt",
    NONE = "NONE",
}

/// Project Page Models ///
export interface ProjectArtius {
    folderName: string;
    projectId: ProjectId; // uniqie project ID
    thumbnail: Array<string>;
    description: string;
    type: ProjectType;
    tags: Array<string>;
    createdDate: string;
    // for model settings presets.
    modelSettings?: ModelSettings | null; //model settings presets.
    trainingSettings?: ArtiusModelTraining | null; // presets for fine tuning model.
    editorAugmentation?: EditorAugmentation | null; // editor presets.
}

// hidden variables too alter promnts.
export interface EditorAugmentation {
    promntAppend: string;
}

//record all transactions
export interface BillableTransactions{
    jobId: string;
    createdDate: string;
    timeCost: number;
    uid:string;
}

export enum ProjectType {
    Template = "template",
    Dreambooth = "dreambooth",
    User = "user",
}

export enum DbName {
    project = "project",
    user = "user",
    images = "images",
    inpainting = "inpainting",
    gallery = "gallery",
    subscription = "subscriptions",
    transactions = "transactions",
    training = "training",
    trainingSettings = "trainingSettings",
    modelVersion = "modelVersion",
}

export enum RoleTypes{
    FREE = "free",
    BASIC = "basic",
    PRO = "pro",
}

export interface xyPos{
    x:number,
    y:number,
}

export interface styleCategories { id: number, name: string, activate: boolean }


// Dream booth for fine tuning.///
export interface ArtiusModelTraining {
    modelType: ArtiusTrainingTypes; /// define the model type, dreambooth or orders.
    aiModelType: ModelsArtius; // the model to genarate this  image stable diffusion or others
    mUID: string | null; // Unqiue ID to identify this model.
    uid: UID | null; // UID, users ID
    modelId: string | null; // model ID generated by the AI platform for identification.
    dateCreated: string | null; // date this model was created.
    trainingStart: string | null; // time training started.
    status: StatusTypes // STatus types Completed, training etc.
    displayName: string; // displane for this model 
    description: string; // description of the model.
    inputs: DreamBoothSettings | SomeOtherModel | null; // model inputs.
    Output: DreamBoothOutput | null; //genrated output, if null means none have been genrated
    examplePrmnt?: string; // example promnt to display
    allowInpainting?:boolean; // if true, then this model can be used in inpainitng.
}

export interface DreamBoothSettings {
    dreamBooth: string;
    files: ImageFiles[];
    instance_prompt: string;
    class_prompt: string;
    zipRef ? : string;
    zipURL ? : string;
    width:number;
    height:number;
}

export interface ImageFiles {
    url: string;
    caption: string;
    fileName:string;
    fileType: string;
    fileSize: number;
    fireRefrence: string;
}

export interface SomeOtherModel {
    SomeOtherModel: string;
    file: string;
    promnt: string;
}

export interface DreamBoothOutput {
    dreamBooth: null;
    completedTime: number; // how long this model took.
    endTime: string;
    modelName: string;
    version:string;
    url?: string[]; /// uRL to download outputs.
}

export enum ArtiusTrainingTypes {
    dreamBooth = "dreamBooth",
    none = "none",
}

// hhtp request to trigger replicate
export interface HTTPTriggerReplicate{
    triggerType: "dreambooth" | "replicateInference"
    id:string;
    demo?:boolean;
}


// HTTP Requests. //
export interface HTTPCreateAiImage {
    requestType: RequestType;
    ImageArtius: ImageArtius;
}

export interface RequestType {
    type: RequestTypeKeys;
    input?: { scale?: number }
}

export enum RequestTypeKeys{
    CREATE = "create",
    RECREATE = "recreate",
    UPRESS = "upress",
    EDIT = "edit",
    REMOVEBG = "removebg",
    INSTRUCT = "instruct"
}

export interface ReplicateResponse {
    id: JobId;
    version: string;
    urls: Urls;
    created_at: Date;
    completed_at: Date;
    status: string;
    input: Input;
    output: string[] | string;
    error?: any;
    logs: string;
    metrics: Metrics;
}
export interface Urls {
    get: string;
    cancel: string;
}

export interface Input {
    prompt: string;
}

export interface Metrics {
    predict_time: number;
}


  //configs
  export interface MenuLinks{
    linkName : string; // th anme of the link
    route  : {to:string}; // if redirection is nessary, we will redirect.
    IconComponent : any; // the icon componet (what icon to show)
    show : boolean; // show nav or not.
    func ? : ()=>void; // execute a function instead
  }
import React from 'react'
import LoginBox from '../../components/loginBox/loginBox';
import './landing.scss'


function Landing() {
  //this function returns the JSX.
  return (
    <React.Fragment>
      <div className="BG">
      <div className="elemntCenter margin-top-displace">
        <div className="loginContainer">

          <div className="loginHeader">Generate AI Art With A Single Prompt</div>
     
          <LoginBox></LoginBox>

        </div>
      </div>
      </div>

    </React.Fragment>
  );
}

export default Landing

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { EditorState, getTrainingData } from '../../../app/states/articleWatch';
import { App, ArtiusModelTraining, DbName,  ProjectArtius, ProjectId, ProjectType, StatusTypes } from '../../../models/shared_models';
import { CreateUpdateProject } from '../../../services/web.service';
import { useNavigate, useParams } from 'react-router-dom';
import { updateAppState } from '../../../app/states/appState';
import TrainingSettings from './trainingSettings/trainingSettings';
import MultiImageUpload from './multiImageUpload/multiImageUpload';
import './trainingEditor.scss'
import SyncIcon from '@mui/icons-material/Sync';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { makeid } from '../../../services/util';
import TextRename from '../../../components/text-rename/text-rename';
import { IconButton, useMediaQuery } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

function TrainingEditor() {
  const { projectId } = useParams();
  const dispatch = useDispatch()
  const appState: App = useSelector((state: any) => state.appState.app as App);
  const trainingSettings: ArtiusModelTraining | null = useSelector((state: any) => (state.imageEditorState as EditorState).trainingSettings);
  //const [projectDetails, setProjectDetails] = React.useState<ProjectArtius | null>(null);
  const projectDetails: ProjectArtius | null = useSelector((state: any) => (state.imageEditorState as EditorState).projectSettings);
  const navigate = useNavigate();
  const mobile = useMediaQuery('(min-width:600px)');
  // component mounting and unmounting
  useEffect(() => {

    getProjectDetails();
    return () => {
      // Anything in here is fired on component unmount.
      //  unsub();
    }
  }, [])

  const onCreateUpdateProject = (folder: ProjectArtius) => {
    console.log("Create new Project", folder)
    // dispatch(updateAppState({ loading: true }))
    //const docRef = await addDoc(collection(db, "user"), folder);
    CreateUpdateProject(appState.uid, folder, DbName.training).then(function () {
      // setAlertMsg("")
      // dispatch(updateAppState({ loading: false }))
      getProjectDetails();
    }).catch((error) => {
      console.log("ERROR", error)
      ///dispatch(updateAppState({ loading: false }))
      // setAlertMsg("Failed to create/update folder. Try refreshing the page and trying again.")
    });
  }

  

  const getProjectDetails = () => {
    // get project details
    dispatch(updateAppState({ loading: true }))
    // get project data
    if(projectId){
    dispatch<any>(getTrainingData({uid:appState.uid, projectId:projectId})).then((x:any)=>{
      // hey ho
      dispatch(updateAppState({ loading: false }))
      if(!x.payload) navigate("/error");
    })
  }

  }

  const createCustomProj = (folder: ProjectArtius) => {
    // create a custom project for image genration, depends of there is model settings.
    dispatch(updateAppState({ loading: true }))
    //create project
    CreateUpdateProject(appState.uid, folder, DbName.project).then(function () {

      navigate("/projects/" + folder.projectId);
      // dispatch(updateAppState({ loading: false }))
    }).catch((error) => {
      console.log("ERROR", error)
      ///dispatch(updateAppState({ loading: false }))
      dispatch(updateAppState({ errorMsg: "Failed to create/update folder. Try refreshing the page and trying again.", loading: false }))
    }).finally(() => {
      dispatch(updateAppState({ loading: false }))
    });

  }


  //this function returns the JSX.
  return (

    <React.Fragment>


      <div className="headerBreacdrumb">
        <span className="titleLink" onPointerDown={() => navigate("/training")}>Fine Tuning</span> / <TextRename  onRename={(v) => projectDetails && onCreateUpdateProject({ ...projectDetails, folderName: v })} disableEditing={false} disableDelete={true} text={projectDetails?.folderName || ""} />
      </div>
      {trainingSettings &&
        <div key={projectDetails?.projectId + "" + trainingSettings?.mUID} >

        {(!projectDetails?.description) && <p className="subTitle">Train your own images and styles with Stable Diffusion. Upload a set of images, at least 5-10, of a person or an object and click &quot;Start Training&quot; to create a custom model that you can use. </p>}
        {(projectDetails?.description) && <p className="subTitle">{projectDetails.description} </p>}


          {(trainingSettings && trainingSettings.status == StatusTypes.PENDING) &&
            <div className="sucess">
              <span className="bold"> <SyncIcon></SyncIcon> Model Training in Progress</span>
              <p></p>
              We are currently training your model, this can take anywhere from 5 to 30 mins. Check back soon! {/* <p className="linkOrange" onClick={refreshStatus}>Click here to check the status.</p> */}
            </div>
          }

          {(trainingSettings && trainingSettings.status == StatusTypes.COMPLETED) &&
            <div className="completed">
              <span className="bold"> <CheckIcon></CheckIcon> Model Training Completed</span>
              <p></p>

              Your model has completed training! 	&nbsp;
               {(projectDetails?.modelSettings) && <span className="linkOrange" onClick={(v) => createCustomProj({
                folderName: "New Fine Tune Project",
                projectId: makeid(5) as ProjectId,
                thumbnail: [],
                description: "",
                createdDate: (new Date()).toISOString(),
                tags: [],
                modelSettings: (projectDetails?.modelSettings && {...projectDetails.modelSettings, modelVersionId: trainingSettings?.mUID || undefined }),
                type: ProjectType.User,
              })} >Start Creating Images with This Model.</span>}

              {(!projectDetails?.modelSettings) && <span>Go to Create &gt; switch to stable Diffusion &gt; Advance Settings &gt; version &gt; choose your model.</span>}

            </div>
          }

          {(trainingSettings && trainingSettings.status == StatusTypes.FAILED) &&
            <div className="failed">
              <span className="bold"> <CloseIcon></CloseIcon> Model Training Failed</span>
              <p></p>
              Training failed. Make sure your images are PNG or JPG, and try again later.
            </div>
          }

          <TrainingSettings ></TrainingSettings>
          <MultiImageUpload></MultiImageUpload>
          
        </div>
      }
    </React.Fragment>
  );
}

export default TrainingEditor

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc, collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
import { ArtiusModelTraining, DbName, DreamBoothSettings, HTTPCreateAiImage, HTTPTriggerReplicate, ImageArtius, ImageFiles, JobId, ModelsArtius, ModelSettings, ProjectArtius,  RequestType, RequestTypeKeys, RoleTypes, StatusTypes, UID } from "../models/shared_models";
import { deleteObject, getStorage, ref, uploadBytes } from "firebase/storage";
import axios from 'axios'
import { getAnalytics, logEvent } from "firebase/analytics";
import { subDetails } from "../models/configs";
import { makeid } from "./util";
import { getPerformance } from "firebase/performance";
import TrainingSettings from "../pages/training/trainingEditor/trainingSettings/trainingSettings";
import store from "../app/store";
import { updateAppState } from "../app/states/appState";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCVBP9RVbewbjbrVaGSJ0PiYUolF6xo9t8",
    authDomain: "conceptart-664c9.firebaseapp.com",
    projectId: "conceptart-664c9",
    storageBucket: "conceptart-664c9.appspot.com",
    messagingSenderId: "782238692834",
    appId: "1:782238692834:web:f1528c0ad8caa2a49c75bb",
    measurementId: "G-DX51H2H9TS"
};

declare const fbq : any;
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics();
export const db = getFirestore(app);
export const auth = getAuth(app);
export const functions = getFunctions(app);
const perf = getPerformance(app);
export let isLocal  = false;
runFunctions()
// Object storage
export const storage = getStorage(app);
export const canvasInpaintingJsonStore = new Map<JobId, { inpaintingRef: JobId, data: [object] }>();
let canvasRef: any = null;
const maxSimulPolls = 3;
let pollingNumber = 0;
let maxConcurrentGenrations = 0;

//pub sub for canvas
 export const CanvasEventStore  = {
    events: new Map(),
    on(eventType: string, fn : ()=>void){
        // add events to the event bus if it does not exist.
        if(!this.events.has(eventType)) this.events.set(eventType, fn)
        return this;
    },
    emit(eventType: string, ...args: any[]){
        //trigger stored function
        if(this.events.has(eventType)) this.events.get(eventType)(...args)
        return this;
    }
} 

function runFunctions() {
    if (process.env.NODE_ENV !== 'production') {
        console.log("Local Host Detected")
        connectFunctionsEmulator(functions, "localhost", 5001);
        isLocal = true;
    }
}

export function setGenerations(connections: number) {
    maxConcurrentGenrations = connections;
}

export function setCanvasRef(canvas: any) {
    canvasRef = canvas;
}

export function getCanvasRef() {
    return canvasRef;
}


// https://storage.googleapis.com/conceptart-664c9.appspot.com/rYu5q6rg9fMGNmnGqOB7NLNwk6q1/LSMEE/zZjx0aWgguVROixIuuaq.png
//string to hash
export function stringToHash(s:string){
    return s.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);              
  }

// create or update user projects.
export function CreateUpdateProject(uid: string, folder: ProjectArtius, folderType: DbName) {
    return setDoc(doc(db, DbName.user, uid, folderType, folder.projectId), folder)
}

// create or update training settings.
export function CreateUpdateTrainingSettings(uid: UID, folder: ProjectArtius, trainingSettings: ArtiusModelTraining ) {
    const newTrainingSettings : ArtiusModelTraining = JSON.parse(JSON.stringify(trainingSettings))
    console.log("SAVEING TRAINING", DbName.user, uid, DbName.training, folder.projectId, DbName.trainingSettings, String(trainingSettings.mUID))
    newTrainingSettings.uid = uid;
    return setDoc(doc(db, DbName.user, uid, DbName.training, folder.projectId, DbName.trainingSettings, String(newTrainingSettings.mUID)), newTrainingSettings)
}


// create or update gallery.
export function CreateUpdateGallery(uid: string, image: ImageArtius) {
    if (!image.modelSettings.project?.projectId) return Promise.reject(new Error('fail'))
    return setDoc(doc(db, DbName.user, uid, DbName.gallery, String(image.modelSettings.jobId)), image)
}

// create or update images.
export function CreateUpdateImage(uid: UID, image: ImageArtius) {
    if (!image.modelSettings.project?.projectId) return null
    image.modelSettings.uid = uid;
    return setDoc(doc(db, DbName.user, uid, DbName.project, image.modelSettings.project.projectId, DbName.images, String(image.modelSettings.jobId)), image)
}

// get User Roles
export async function getUserRoles(uid: string): Promise<RoleTypes> {
    const docRef = collection(db, DbName.user, uid, DbName.subscription)
    const q = query(docRef, where("status", "==", "active"), orderBy("created", "desc"));
    const tmpFolder: Array<any> = [];
    const roles = await getDocs(q).then((querySnapshot) => {

        querySnapshot.forEach((doc) => {
            tmpFolder.push(doc.data() as any)

        });

        const activeSub = tmpFolder.filter(x => x.status == "active");

        if (tmpFolder.length > 0 && activeSub.length > 0) {
            // if there is role data
            console.log(activeSub[0].items[0].price.product.metadata.firebaseRole)
            return activeSub[0].items[0].price.product.metadata.firebaseRole;
        } else {
            return RoleTypes.FREE
        }
    });
    return roles;
}

// create or update inpaint masks.
export function CreateUpdateInpainting(uid: string, image: ImageArtius, data: object | undefined) {

    if (!image.modelSettings.project?.projectId) return null
    console.log("Save Inpainting Mask to->", image)
    return setDoc(doc(db, DbName.user, uid, DbName.project, image.modelSettings.project?.projectId, DbName.inpainting, String(image.modelSettings.refJobId)), { data: JSON.stringify(data) })
}

// Generate AI Images.
export async function createImage(uid: UID, setRequestType: RequestTypeKeys, modelSettings: ModelSettings, project: ProjectArtius) {

    if (maxConcurrentGenrations > maxSimulPolls) return null
    // const image : ImageArtius = JSON.parse(JSON.stringify(newimage));
    const newModelSettings = JSON.parse(JSON.stringify(modelSettings))
    newModelSettings.project = project;
    // add a new empty image into firestore DB
    const image: ImageArtius = { modelSettings: CreateNewImageHist(newModelSettings), imageDetails: null }
    //image  = JSON.parse(JSON.stringify(newimage));
    CreateUpdateImage(uid, image)

    //save canvas image data to fire store, use the previous JObid (refJobId) becuase a new one has alredy been genrated
    if (image.modelSettings.refJobId && canvasInpaintingJsonStore.has(image.modelSettings.refJobId)) {
        await CreateUpdateInpainting(uid, image, canvasInpaintingJsonStore.get(image.modelSettings.refJobId)?.data)
    }

    //Genrate New Seed
    if ((image?.modelSettings?.modelInputs as any)?.seed
        && !image.modelSettings.useSeed) {
        //genrate new SEED if this is has a seed property.
        (image.modelSettings.modelInputs as any).seed = Math.round(Math.random() * 1000000);
    }
    console.log("Create AI Image:", image)
    const addMessage = httpsCallable(functions, 'createAiImage');
    const requestType: RequestType = { type: setRequestType }
    logEvent(analytics, 'video_play' , { name: 'video_play'});
    fbq('track', 'Lead');
    return addMessage({ ImageArtius: image, requestType } as HTTPCreateAiImage).catch((err)=>{
        //catch errpr
        //update DB as failed.
        image.modelSettings.status = StatusTypes.FAILED;
        image.modelSettings.error = "Server Error Occured : Request Was Rejected"
        CreateUpdateImage(uid, image)
    })
  
    //return CreateUpdateImage(uid, folder, image); //grisley bear
}



// upress image
export async function upressImage(newimage: ImageArtius, scale: number, uid: UID) {
    if (maxConcurrentGenrations > maxSimulPolls) return null
    const image: ImageArtius = JSON.parse(JSON.stringify(newimage));

    //Genrate New Seed
    image.modelSettings.displayWidth = image.modelSettings.width * scale;
    image.modelSettings.displayHeight = image.modelSettings.height * scale;
    console.log("Upress Image:", image)
    const addMessage = httpsCallable(functions, 'createAiImage');
    const requestType: RequestType = {
        type: RequestTypeKeys.UPRESS,
        input: { scale }
    }
    return addMessage({ ImageArtius: image, requestType } as HTTPCreateAiImage)
    //return CreateUpdateImage(uid, folder, image);
}

//delete image
export async function deleteImage(newimage: ImageArtius) {
    const image: ImageArtius = JSON.parse(JSON.stringify(newimage));

    console.log("Delete Image:", image)
    const addMessage = httpsCallable(functions, 'deleteImage');
    return addMessage(image)
    //return CreateUpdateImage(uid, folder, image);
}


//trigger replicate again
export async function triggerReplicate(request: HTTPTriggerReplicate) {
    const addMessage = httpsCallable(functions, 'triggerReplicateWebHook');
    request.demo = isLocal;
    return addMessage(request)
    //return CreateUpdateImage(uid, folder, image);
}


//delete image
export async function createTraining(trainingData: ArtiusModelTraining) {
    const newTrainingSettings: ArtiusModelTraining = JSON.parse(JSON.stringify(trainingData));

    console.log("Traing Settings API:", newTrainingSettings)
    const addMessage = httpsCallable(functions, 'trainAI');
    return addMessage(newTrainingSettings)
    //return CreateUpdateImage(uid, folder, image);
}

//delete Project
export async function deleteProject(artiusProject: ProjectArtius) {
    const newArtiusProject: ImageArtius = JSON.parse(JSON.stringify(artiusProject));

    console.log("Delete Projct:", newArtiusProject)
    const addMessage = httpsCallable(functions, 'deleteProject');
    return addMessage(newArtiusProject)
    //return CreateUpdateImage(uid, folder, image);
}

//delete training Project
export async function deleteTrainingProject(artiusProject: ProjectArtius) {
    const newArtiusProject: ImageArtius = JSON.parse(JSON.stringify(artiusProject));
    console.log("Delete Projct:", newArtiusProject)
    const addMessage = httpsCallable(functions, 'deleteProjectTraining');
    return addMessage(newArtiusProject)
    //return CreateUpdateImage(uid, folder, image);
}


// add new image into hostory
export function CreateNewImageHist(modelSettings: ModelSettings): ModelSettings {
    // create a new imge history and add it into store
    const newModelSettings: ModelSettings = JSON.parse(JSON.stringify(modelSettings))
    newModelSettings.status = StatusTypes.PENDING
    newModelSettings.startTime = (new Date()).toISOString();
    if (newModelSettings.jobId) newModelSettings.refJobId = newModelSettings.jobId; // set the ref ID to the previous job ID.
    newModelSettings.jobId = makeid(20) as JobId

    return newModelSettings
}


// poll the status of AI job.
export function pollJob(newimage: ImageArtius) {
    //const image: ImageArtius = JSON.parse(JSON.stringify(newimage));
    const addMessage = httpsCallable(functions, 'checkAiStatus');
    pollingNumber += 1;
    return addMessage({ ImageArtius: newimage } as HTTPCreateAiImage)
    //return CreateUpdateImage(uid, folder, image);
}

// poll the status of AI job.
export function autoSuggest(promnt: string) {
    const addMessage = httpsCallable(functions, 'promntSuggest');
    return addMessage({ promnt })
    //return CreateUpdateImage(uid, folder, image);
}


// upload image 
export function uploadAttachement(uid: string, file: File, tmpFolderName="tempfiles") {
    const storageRef = ref(storage, `${uid}/${tmpFolderName}/${file.name}`);
    console.log("upload file..", file)
    return uploadBytes(storageRef, file)
}


export function deleteFileStorage(refPath:string){
    // delete file from storage
    const desertRef = ref(storage, refPath);
    return deleteObject(desertRef)
}

export function uploadFile(address: string, file: File) {
    const storageRef = ref(storage, address);
    console.log("upload file..", file)
    return uploadBytes(storageRef, file)
}


// delete file
export function deleteFile(fireRef : string){
    const desertRef = ref(storage, fireRef);
    return deleteObject(desertRef);
}   

export async function openUserSubPortal() {
    // helo
    const functionRef = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
    const { data } = await functionRef({
        returnUrl: window.location.origin,
        locale: "auto", // Optional, defaults to "auto"
        // configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
    });
    window.location.assign((data as any).url);
}

/// TO post a news articles, scrape and summarize.
export function searchAutosuggest(search: string) {
    // this.redirect(url);
    const body = {
        "text": search,
        "image": null,
        "image_url": null,
        "embedding_input": null,
        "modality": "image",
        "num_images": 20,
        "indice_name": "laion_400m",
        "num_result_ids": 20,
        "use_mclip": false,
        "deduplicate": true,
        "use_safety_model": true,
        "use_violence_detector": true,
        "aesthetic_score": "8",
        "aesthetic_weight": "0.1"
    }
    return axios(
        {
            url: 'https://knn5.laion.ai/knn-service',
            method: 'post',
            data: body,
        });
}


// too upload training images.
// event refers to the file uploade selected files
//exisiting files are teh ones that are alredy uplaoded previously.
export const uploadTrainingImages= async (event: any,  trainingSettings : ArtiusModelTraining, maxImages: number, uid:UID): Promise<{ files: ImageFiles[], error: string | null }> => {
    /// when user clicks on file upload
    const newFiles: ImageFiles[] = [];
    const files = event.target.files;
    //dispatch(updateAppState({ loading: true }))
    //console.log(files)
    const existingFiles = (trainingSettings?.inputs as DreamBoothSettings)?.files || [];
    // image is compatiple, being upload.
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

        // if exceeds max images
        if (files.length > maxImages) {
            //stop upload
            reject({ files: [], error: `Maximum images exceeded: ${maxImages}` })
            return;
        }

        for (let i = 0; i < files.length; i++) {
            //process multiple selected files
            store.dispatch(updateAppState({loadingMsg: `Uploading Image : ${i+1}/${files.length}`}))
            const filename = makeid(7);
            const type = files[i].type;
            const size = files[i].size;
            if (size < 100233000 && (type.includes("png") || type.includes("jpg") || type.includes("jpeg"))) {

                const fileRef = `${uid}/${DbName.trainingSettings}/${trainingSettings?.mUID}/${filename}`;
                
                await uploadFile(fileRef, files[i]).then((snapshot: any) => {
                    // update training settings
                    const newFile: ImageFiles = {
                        url: `https://firebasestorage.googleapis.com/v0/b/conceptart-664c9.appspot.com/o/${uid}%2F${DbName.trainingSettings}%2F${trainingSettings?.mUID}%2F${filename}?alt=media`,
                        caption: "",

                        fileName: String(filename),
                        fileType: type,
                        fileSize: size,
                        fireRefrence: fileRef
                    }
                    console.log("NEW FILE:", newFile)
                    newFiles.push(newFile)
                });
            } else {
                //250MB
                console.log("ERROR")
                reject({ files: [], error: `Image must be < 250MB, PNG, JPG or JPEG` })
            }

        }
        store.dispatch(updateAppState({loadingMsg: `Loading...`}))

        resolve({ files: [...existingFiles, ...newFiles], error: null })
    });

}


export const getSubDetails = (subType: RoleTypes) => {
    // return role
    return subDetails.get(subType)
}
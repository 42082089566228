import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppConfigs, AppState } from '../../models/shared_models'

// Define a type for the slice state

// Define the initial state using that type
const initialState: AppState = {
  app: { 
    loading: false ,
    errorMsg: "" ,
    emailVerified:false,
    loggedIn:false,
    email:"",
    role: null,
    uid:"",
    requests:0,
    appConfig: AppConfigs.DEFAULT,
    loadingMsg:"Loading..."
  }
} as AppState


export const appSlice = createSlice({
  name: 'appState',
  initialState: initialState,
  reducers: {

    updateAppState: (state: AppState, action: PayloadAction<any>) => {
      state.app = { ...state.app, ...action.payload} 
    },

  },
})

// Action creators are generated for each case reducer function
export const { updateAppState } = appSlice.actions

export default appSlice.reducer

// Other code such as selectors can use the imported `RootState` type
export const getAppState = (state: AppState) => state.app;
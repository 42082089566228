import React from "react";

export  default function useDoOnce() : (name:string)=>boolean{

    // the function saves to local storage.
    return (name:string)=>{
        const data = localStorage.getItem(name);
        if(data){
            // if data exist we will return false
            return false;
        }else{
            localStorage.setItem(name, 'true');
            return true
        } 
    }
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import store from './app/store'
import { Provider } from 'react-redux'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
/* const firebaseConfig = {
  apiKey: "AIzaSyCVBP9RVbewbjbrVaGSJ0PiYUolF6xo9t8",
  authDomain: "conceptart-664c9.firebaseapp.com",
  projectId: "conceptart-664c9",
  storageBucket: "conceptart-664c9.appspot.com",
  messagingSenderId: "782238692834",
  appId: "1:782238692834:web:f1528c0ad8caa2a49c75bb",
  measurementId: "G-DX51H2H9TS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app); */


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme({
  typography: {
    fontFamily: [
      'Lato',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),
    "fontSize": 14,
    "fontWeightLight": 200,
    "fontWeightRegular": 400,
    "fontWeightMedium": 700,
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#272742",
          color: "#737FCE",
        }
      }
    },
    MuiIconButton: {
      styleOverrides: { colorPrimary: "#737FCE", },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: 'white',
        }
      }
    }
  },
  palette: {
    background: {
      default: "#23253C"
    },
    text: {
      primary: "#737FCE"
    }
  },

});


root.render(
  
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

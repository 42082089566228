import { ArtiusModelTraining, ArtiusPermissions, ArtiusTrainingTypes, DallESettings, DreamBoothSettings, ImageArtius, InpaintingToolTypes, InpaintingWidget,  JobId,  ModelsArtius,  RoleTypes, StableDiffusionSettings, StableDiffusionWebSettings, StatusTypes, StyleTypes, SubscriptionDetails, styleCategories } from "./shared_models";

// to genrate a random hash, useful to create documents and collection ids.
export function makeid(length: number): string {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

export const defaultPermissions : ArtiusPermissions ={
    hasAiImageCreation : true,
}


export const subDetails = new Map<RoleTypes, SubscriptionDetails>();
// add Subscription Details.
subDetails.set(RoleTypes.BASIC, {
    price: 19.99,
    plan: RoleTypes.BASIC,
    maxRequest: 2000,
    name: "Basic Plan"
});

subDetails.set(RoleTypes.PRO, {
    price: 49.99,
    plan: RoleTypes.PRO,
    maxRequest: 5000,
    name: "Pro Plan"
});

subDetails.set(RoleTypes.FREE, {
    price: 0,
    plan: RoleTypes.FREE,
    maxRequest: 500,
    name: "Free Plan"
});



export const stableDiffusionInitial: StableDiffusionSettings = {
    prompt_strength: 0.3,
    num_outputs: 1,
    num_inference_steps: 78,
    guidance_scale: 13,
    seed: Math.round(Math.random() * 10000),
    stableDiffusion: ModelsArtius.stableDiffusion,
    negative_prompt: "amateur image, blurry, out of frame, ugly, low quality image, low resolution, thumbnail, unprofessional"
};

export const stableDiffusionWebInitial: StableDiffusionWebSettings = {
    prompt_strength: 7, //[ 0 .. 35 ]
    num_outputs: 1,
    num_inference_steps: 50,
    seed: Math.round(Math.random() * 10000),
    stableDiffusionWeb: ModelsArtius.stableDiffusionWeb,
    style_preset: "enhance",
    negative_prompt: "amateur image, blurry, out of frame, ugly, low quality image, low resolution, thumbnail, unprofessional"
};


export const imageInitial: ImageArtius = {
    imageDetails: null,
    modelSettings: {
      modelType: ModelsArtius.stableDiffusionWeb,
      width: 512,
      jobId: makeid(20) as JobId,
      height: 512,
      aspectRatioWidth: 16,
      aspectRatioheight: 9,
      imgPrompt: "",
      startTime:"",
      username:"",
      useSeed:false,
      style:{id:1309},
      uid:null,
      status:StatusTypes.NONE,
      modelInputs: stableDiffusionWebInitial,
      project: null,
      modelVersionId: "kandinsky"
      //inpainting:{enableInpainting:false,inpaintingRef:null}
    }
}

export const trainingInitial: ArtiusModelTraining = {
    modelType: ArtiusTrainingTypes.dreamBooth,
    aiModelType: ModelsArtius.stableDiffusion, // the AI model.
    mUID: null, // usually the project ID
    modelId: null, // ai genrated ID
    dateCreated:null,
    trainingStart:null,
    displayName:"Untitled Model",
    description:"",
    Output:null,
    inputs:null,
    uid:null,
    status: StatusTypes.NONE
}

//style library categories
export const styleLibraryCategories: styleCategories[] = [
    { id: 0, name: "All", activate: true },
    { id: 1, name: "Photography", activate: false },
    { id: 2, name: "Branding", activate: false },
    { id: 3, name: "Graphic Design", activate: false },
    { id: 6, name: "Interior Design", activate: false },
    { id: 7, name: "UI/UX", activate: false },
    { id: 8, name: "Fashion", activate: false },
    { id: 9, name: "Merchandise", activate: false },
    { id: 10, name: "Character Design", activate: false },
    { id: 11, name: "Product Design", activate: false },
    { id: 12, name: "Architecture", activate: false },
    { id: 13, name: "Social Post Design", activate: false },
    { id: 14, name: "Textures", activate: false },
    { id: 15, name: "Illustration", activate: false },
    { id: 16, name: "Matt Painting", activate: false },
    { id: 17, name: "Advertising", activate: false },
  ]


// THis is for Sub models
export const modelVersionsInitial: ArtiusModelTraining[] = [

    {
        modelType: ArtiusTrainingTypes.none,
        aiModelType: ModelsArtius.stableDiffusion, // the AI model.
        mUID: "00", // usually the project ID
        modelId: "SD2.1", // ai genrated ID
        dateCreated: null,
        trainingStart: null,
        displayName: "Stable Diffusion 2.1",
        description: "",
        Output: { dreamBooth: null, completedTime: 0, modelName: "", endTime: "", version: "27b93a2413e7f36cd83da926f3656280b2931564ff050bf9575f1fdf9bcd7478" },
        inputs: null,
        uid: null,
        status: StatusTypes.COMPLETED,
    },
    {
        modelType: ArtiusTrainingTypes.none,
        aiModelType: ModelsArtius.stableDiffusion, // the AI model.
        mUID: "SD1.5", // usually the project ID
        modelId: "SD1.5", // ai genrated ID
        dateCreated: null,
        trainingStart: null,
        displayName: "Stable Diffusion 1.5",
        description: "",
        Output: { dreamBooth: null, completedTime: 0, modelName: "", endTime: "", version: "27b93a2413e7f36cd83da926f3656280b2931564ff050bf9575f1fdf9bcd7478" },
        inputs: null,
        uid: null,
        status: StatusTypes.COMPLETED,
    },
    {
        modelType: ArtiusTrainingTypes.none,
        aiModelType: ModelsArtius.stableDiffusion, // the AI model.
        mUID: "SDMJ", // usually the project ID
        modelId: "SDMJ", // ai genrated ID
        dateCreated: null,
        trainingStart: null,
        displayName: "Open Journey",
        examplePrmnt:"mdjrny-v4 style of a women",
        description: "Use 'mdjrny-v4 style' in your Prompt to apply this style.",
        Output: { dreamBooth: null, completedTime: 0, modelName: "", endTime: "", version: "9936c2001faa2194a261c01381f90e65261879985476014a0a37a334593a05eb" },
        inputs: null,
        uid: null,
        status: StatusTypes.COMPLETED,
    },
    {
        modelType: ArtiusTrainingTypes.none,
        aiModelType: ModelsArtius.stableDiffusion, // the AI model.
        mUID: "anything3", // usually the project ID
        modelId: "anything3", // ai genrated ID
        dateCreated: null,
        trainingStart: null,
        displayName: "Anything v4.0",
        examplePrmnt:"1girl, white hair, golden eyes, beautiful eyes, detail, flower meadow, cumulonimbus clouds, lighting, detailed sky, garden",
        description: "High-quality, highly detailed anime style stable-diffusion",
        Output: { dreamBooth: null, completedTime: 0, modelName: "", endTime: "", version: "42a996d39a96aedc57b2e0aa8105dea39c9c89d9d266caf6bb4327a1c191b061" },
        inputs: null,
        uid: null,
        status: StatusTypes.COMPLETED,
    },
    {
        modelType: ArtiusTrainingTypes.none,
        aiModelType: ModelsArtius.stableDiffusion, // the AI model.
        mUID: "materialDiffusion", // usually the project ID
        modelId: "materialDiffusion", // ai genrated ID
        dateCreated: null,
        trainingStart: null,
        displayName: "Material Diffusion",
        examplePrmnt:"Mossy Runic Bricks seamless texture, trending on artstation, stone, moss, base color, albedo, 4k",
        description: "Stable diffusion fork for generating tileable outputs using v1.5 model",
        Output: { dreamBooth: null, completedTime: 0, modelName: "", endTime: "", version: "a42692c54c0f407f803a0a8a9066160976baedb77c91171a01730f9b0d7beeff" },
        inputs: null,
        uid: null,
        status: StatusTypes.COMPLETED,
    },
    {
        modelType: ArtiusTrainingTypes.none,
        aiModelType: ModelsArtius.stableDiffusion, // the AI model.
        mUID: "wavyfusion", // usually the project ID
        modelId: "wavyfusion", // ai genrated ID
        dateCreated: null,
        trainingStart: null,
        displayName: "Wavy Diffusion",
        examplePrmnt:"batman wa-vy style",
        description: "Dreambooth trained on a very diverse dataset ranging from photographs to paintings",
        Output: { dreamBooth: null, completedTime: 0, modelName: "", endTime: "", version: "3a38e1795ef77e3be3d6eb77fbafaeec79e67d13ee0025b36a93bb17e540efc9" },
        inputs: null,
        uid: null,
        status: StatusTypes.COMPLETED,
    },
    {
        modelType: ArtiusTrainingTypes.none,
        aiModelType: ModelsArtius.stableDiffusion, // the AI model.
        mUID: "kandinsky", // usually the project ID
        modelId: "kandinsky", // ai genrated ID
        dateCreated: null,
        trainingStart: null,
        displayName: "Kandinsky 2.1",
        examplePrmnt:"",
        description: "Kandinsky 2.1 inherits best practicies from Dall-E 2 and Latent diffucion, while introducing some new ideas.",
        Output: { dreamBooth: null, completedTime: 0, modelName: "", endTime: "", version: "601eea49d49003e6ea75a11527209c4f510a93e2112c969d548fbb45b9c4f19f" },
        inputs: null,
        uid: null,
        status: StatusTypes.COMPLETED,
    },
    {
        modelType: ArtiusTrainingTypes.none,
        aiModelType: ModelsArtius.stableDiffusion, // the AI model.
        mUID: "SDPattern", // usually the project ID
        modelId: "SDPattern", // ai genrated ID
        dateCreated: null,
        trainingStart: null,
        displayName: "Pattern SD",
        examplePrmnt:"",
        description: "Genrate seamless patterns with Stable diffusion",
        Output: { dreamBooth: null, completedTime: 0, modelName: "", endTime: "", version: "a42692c54c0f407f803a0a8a9066160976baedb77c91171a01730f9b0d7beeff" },
        inputs: null,
        uid: null,
        status: StatusTypes.COMPLETED,
    },
    {
        modelType: ArtiusTrainingTypes.none,
        aiModelType: ModelsArtius.stableDiffusion, // the AI model.
        mUID: "SDAdImage", // usually the project ID
        modelId: "SDAdImage", // ai genrated ID
        dateCreated: null,
        trainingStart: null,
        displayName: "AD BG Replacement",
        examplePrmnt:"",
        allowInpainting : true,
        description: "replace backgrounds in advertising images.",
        Output: { dreamBooth: null, completedTime: 0, modelName: "", endTime: "", version: "71c8441208070ea2c22be006d05a6058717d7e2db5fd709b092f2159a6c0e44e" },
        inputs: null,
        uid: null,
        status: StatusTypes.COMPLETED,
    }
]


export const dreamBoothSettingsInitial: DreamBoothSettings = {
    dreamBooth: "",
    files: [],
    instance_prompt: "",
    class_prompt: "",
    width: 512,
    height: 512
}

/* 
url: string;
caption: string;
fileName:string;
fileType: string;
fileSize: string; */


export const dallEInitial: DallESettings = {
    dalle: ModelsArtius.dalle,
    literalness: 5,
    awesomeness: 45,
    negative_prompt:""
}

export const inpaintingInitial: InpaintingWidget = {
    toolType: InpaintingToolTypes.MOVE,
    brushSize: 15
}



export const stylesInitial: Array<StyleTypes> = [
 
    {
        name: "None",
        id: 1,
        appendPromnt: "",
        preAppendPromnt: "",
        modelType: ModelsArtius.stableDiffusionWeb,
        modelInputs: stableDiffusionWebInitial
    },

    {
        name: "Default",
        id: 1309,
        appendPromnt: "",
        preAppendPromnt: "",
        modelType: ModelsArtius.stableDiffusionWeb,
        modelInputs: stableDiffusionWebInitial
    },

    {
        name: "Open Journey",
        id: 22,
        appendPromnt: ", beautiful, trending artstation, award winning, professional image, trending artstation, @DeviantArt",
        negative_prompt:"amateur image, blurry, out of frame, ugly, low quality image, low resolution, thumbnail, unprofessional",
        preAppendPromnt: "",
        modelType: ModelsArtius.stableDiffusion,
        modelSettings: {...imageInitial.modelSettings, modelVersionId: "SDMJ"},
        modelInputs: stableDiffusionInitial
    },

    {
        name: "Triadic Colors, Illustration",
        id: 30,
        appendPromnt: ", light dust, magnificent, details, sharp focus, elegant, highly detailed, illustration, by tyler edlin, ivan aivazovsky, edmund leighton, and studio ghibli intricate, beautiful, triadic contrast colors, trending artstation, pixiv, digital art",
        preAppendPromnt: "Splash art of ",
        negative_prompt:"bad anatomy, amateur image, bad proportions, blurry, cloned face, deformed, disfigured, extra arms, extra fingers, extra limbs, extra legs, fused fingers, gross proportions, long neck, malformed limbs, missing arms, missing legs, mutated hands, mutation, mutilated, morbid, out of frame, poorly drawn hands, poorly drawn face, too many fingers, ugly, low quality image, low resolution, thumbnail",
        modelInputs: stableDiffusionWebInitial,
        modelType: ModelsArtius.stableDiffusionWeb,
        examplePromnt: "Astronaut On Mars",
        image: "style30.jpg",
        cat:[15],
    },

    {
        name: "Anime High Quality",
        id: 333,
        appendPromnt: "",
        preAppendPromnt: "",
        negative_prompt:"bad anatomy, amateur image, bad proportions, blurry, cloned face, deformed, disfigured, extra arms, extra fingers, extra limbs, extra legs, fused fingers, gross proportions, long neck, malformed limbs, missing arms, missing legs, mutated hands, mutation, mutilated, morbid, out of frame, poorly drawn hands, poorly drawn face, too many fingers, ugly, low quality image, low resolution, thumbnail",
        modelInputs: stableDiffusionInitial,
        modelType: ModelsArtius.stableDiffusion,
        examplePromnt: "1girl, white hair, golden eyes, beautiful eyes, detail, flower meadow, cumulonimbus clouds, lighting, detailed sky, garden",
        image: "style333.jpg",
        cat:[15,10],
        modelSettings: {...imageInitial.modelSettings, modelVersionId: "anything3"}
    },


    {
        name: "Stock Photo, Beauty",
        id: 32,
        appendPromnt: ", Photo by national geographic, highly detail, award winning photo, HD, 4k, Stock Photo",
        preAppendPromnt: "A photo of ",
        cat:[1],
        examplePromnt: "A Red London bus",
        image: "style32.jpg",
        negative_prompt:"bad anatomy, amateur image, bad proportions, blurry, cloned face, deformed, disfigured, extra arms, extra fingers, extra limbs, extra legs, fused fingers, gross proportions, long neck, malformed limbs, missing arms, missing legs, mutated hands, mutation, mutilated, morbid, out of frame, poorly drawn hands, poorly drawn face, too many fingers, ugly, low quality image, low resolution, thumbnail",
        modelInputs: {...stableDiffusionWebInitial, style_preset:"photographic"},
        modelType: ModelsArtius.stableDiffusionWeb
    },
    {
        name: "Reaistic Profile Picture",
        id: 909,
        appendPromnt: ", Canon EOS R3, f/1.4, ISO 200, 1/160s, 8K, RAW, unedited, symmetrical balance",
        preAppendPromnt: "",
        cat:[1],
        examplePromnt: "artius.xxx in the park with his dog",
        image: "style909.jpg",
        negative_prompt:"bad anatomy, amateur image, bad proportions, blurry, cloned face, deformed, disfigured, extra arms, extra fingers, extra limbs, extra legs, fused fingers, gross proportions, long neck, malformed limbs, missing arms, missing legs, mutated hands, mutation, mutilated, morbid, out of frame, poorly drawn hands, poorly drawn face, too many fingers, ugly, low quality image, low resolution, thumbnail, duplicate people",
        modelInputs:  {...stableDiffusionInitial},
        modelType: ModelsArtius.stableDiffusion,
        autocomplete:[
            {value:"linkedin profile of artius.JohnDoe, professional, corporate picture"},
            {value:"artius.xxx in the park with his dog"},
            {value:"artius.xxx in a private jet, smiling"} ,
            {value:"artius.xxx on the streets of new york, detailed eyes"} ,
            {value:"artius.xxx in the club"},
            {value:"artius.xxx wearing Balenciaga"},
        ]
    },
    {
        name: "Stock Photo, Landscape",
        id: 36,
        appendPromnt: ",  atmospheric, hyper realistic, 8k, epic composition, cinematic, octane render, artstation landscape vista photography by Carr Clifton & Galen Rowell, 16K resolution, Landscape veduta photo by Dustin Lefevre & tdraw, 8k resolution, detailed landscape painting by Ivan Shishkin, DeviantArt, Flickr, rendered in Enscape, Miyazaki, Nausicaa Ghibli, Breath of The Wild, 4k detailed post processing, artstation, rendering by octane",
        preAppendPromnt: "",
        cat:[1],
        examplePromnt: "Winter landscape with fir trees",
        image: "style36.jpg",
        negative_prompt:"amateur image, blurry, out of frame, ugly, low quality image, low resolution, thumbnail, unprofessional",
        modelInputs:  {...stableDiffusionWebInitial, style_preset:"photographic"},
        modelType: ModelsArtius.stableDiffusionWeb

    },
    {
        name: "Anime Background Matte Painting",
        id: 12345,
        appendPromnt: ", background matt painting, masterpiece, best quality, high detail",
        preAppendPromnt: "",
        cat:[15, 16],
        examplePromnt: "city skylines",
        image: "style109.jpg",
        negative_prompt:"amateur image, blurry, out of frame, ugly, low quality image, low resolution, thumbnail, unprofessional",
        modelType: ModelsArtius.stableDiffusion,
        modelSettings: {...imageInitial.modelSettings, width:1024, height:512,  modelVersionId: "anything3"}

    },

    {
        name: "Album Cover Design",
        id: 1244445,
        appendPromnt: ", album cover, EP cover art, cover design, trending on artstation, digital art, exquisite hyper details, 4k",
        preAppendPromnt: "image of ",
        cat:[3,9,15],
        examplePromnt: "drowning man",
        image: "style12345.jpg",
        negative_prompt:"amateur image, blurry, out of frame, ugly, low quality image, low resolution, thumbnail, unprofessional",
        modelType: ModelsArtius.stableDiffusionWeb,
        modelInputs: {...stableDiffusionWebInitial, style_preset:"fantasy-art"},
    },

    {
        name: "Vintage Vector Art",
        id: 124115,
        appendPromnt: ", vintage vector art, vintage poster",
        preAppendPromnt: "",
        cat:[3, 15],
        examplePromnt: "airplane",
        image: "style10293.jpg",
        negative_prompt:"amateur image, blurry, out of frame, ugly, low quality image, low resolution, thumbnail, unprofessional",
        modelType: ModelsArtius.stableDiffusionWeb,
        modelInputs: {...stableDiffusionWebInitial, style_preset:"digital-art"},
    },

    {
        name: "Flat Style Illustration",
        id: 112233,
        appendPromnt: ", shutterstock, analytical art, minimalist background, presentation, stats, professional illustration, wide screenshot, listing image, trending on artstation, award winning illustration",
        preAppendPromnt: "mdjrny-v4 style of an illustration of ",
        cat:[3,15],
        examplePromnt: "a group of people sitting around a table",
        image: "style112233.jpg",
        negative_prompt:"amateur image, blurry, out of frame, ugly, low quality image, low resolution, thumbnail, unprofessional",
        modelType: ModelsArtius.stableDiffusion,
        modelSettings: {...imageInitial.modelSettings, modelVersionId: "SDMJ"}

    },

    {
        name: "Jewelry Product Render",
        id: 366,
        preAppendPromnt: "a jewelry design, ",
        appendPromnt: ", gemstones and diamonds, luxury, closeup, product view, trending on artstation, cgsociety, ultra quality, digital art, exquisite hyper details, 4k, Soft illumination, dreamy, fashion, rendering by unreal engine, keyshot",
        cat:[9,11],
        examplePromnt: "panda themed ring",
        image: "style366.jpg",
        negative_prompt:"amateur image,  blurry, ugly, low quality image, low resolution, thumbnail",
        modelType: ModelsArtius.stableDiffusionWeb,
        modelInputs: {...stableDiffusionWebInitial, style_preset:"3d-model"},

    },
    {
        name: "Bibliclal Art, Illustration",
        id: 3911,
        appendPromnt: " by Charlie Bowater, Greg Rutkowski, Marc Simonetti and James Paick, trending on artstation, @DeviantArt",
        examplePromnt: "Moses parting the red sea",
        preAppendPromnt: "",
        image: "style3911.jpg",
        cat:[15],
        modelInputs: {...stableDiffusionWebInitial, style_preset:"fantasy-art"},
        modelType: ModelsArtius.stableDiffusionWeb,
        negative_prompt:"bad anatomy, amateur image, bad proportions, blurry, cloned face, deformed, disfigured, extra arms, extra fingers, extra limbs, extra legs, fused fingers, gross proportions, long neck, malformed limbs, missing arms, missing legs, mutated hands, mutation, mutilated, morbid, out of frame, poorly drawn hands, poorly drawn face, too many fingers, ugly, low quality image, low resolution, thumbnail",
        autocomplete:[
        {value:"Moses parting the red sea"},
        {value:"tower of Babel"} ,
        {value:"the three wise men on camels in the desert"} ,
        {value:"Moses speaks to the burning bush"}]
    },

    {
        name: "Vintage Vector Art",
        id: 39,
        appendPromnt: "",
        examplePromnt: "monster truck",
        preAppendPromnt: "flat vintage vector graphic pack monster truck",
        image: "style907.jpg",
        cat:[3],
        modelInputs: stableDiffusionInitial,
        modelType: ModelsArtius.stableDiffusion
    },
    {
        name: "Tattoo Outline",
        id: 40,
        appendPromnt: ", flash sheet, tattoo outline style, American Traditional, trending on artstation, @DeviantArt",
        preAppendPromnt: "",
        examplePromnt: "American Bald Eagle",
        cat:[3],
        image: "style40.jpg",
        modelType: ModelsArtius.stableDiffusion,
        modelInputs: stableDiffusionInitial
    },

    {
        name: "Vector Flat Style Pastel",
        id: 41,
        appendPromnt: " in flat style, pastel color palette",
        preAppendPromnt: "vector illustration of ",
        examplePromnt: "a girl listening to music",
        cat:[3],
        image: "style41.jpg",
        modelType: ModelsArtius.stableDiffusion,
        modelInputs: stableDiffusionInitial
    },

    {
        name: "Die Cut Stickers Kawaii",
        id: 908,
        appendPromnt: " sticker, white background, illustration minimalism, vector, paste colors",
        preAppendPromnt: "die cut sticker, cute kawaii ",
        examplePromnt: "girl listening to music",
        cat:[9],
        image: "style908.jpg",
        modelType: ModelsArtius.stableDiffusion,
        modelInputs: stableDiffusionInitial
    },
    {
        name: "Vector Logo",
        id: 210,
        appendPromnt: ", behance, trending on deviant art, unbelievable logo, high quality",
        preAppendPromnt: "vector logo of ",
        examplePromnt: "stock trading shark",
        cat:[3, 2],
        image: "style20.jpg",
        modelType: ModelsArtius.stableDiffusionWeb,
        modelInputs: {...stableDiffusionWebInitial, style_preset:"digital-art"},
    },
    {
        name: "Pattern Maker (Tiled Images)",
        id: 9998,
        appendPromnt: " seamless texture, trending on artstation, 4k, triadic colors",
        preAppendPromnt: "",
        examplePromnt: "",
        cat:[9, 14],
        image: "styletesxture.jpg",
        modelType: ModelsArtius.stableDiffusion,
        modelInputs: {...stableDiffusionInitial},
        modelSettings: {...imageInitial.modelSettings, modelVersionId: "SDPattern"},
    },
    {
        name: "Product Shot BG Replacement",
        id: 99982,
        appendPromnt: "",
        preAppendPromnt: "",
        examplePromnt: "",
        cat:[9, 17],
        image: "style99982.jpg",
        modelType: ModelsArtius.stableDiffusion,
        modelInputs: {...stableDiffusionInitial},
        modelSettings: {...imageInitial.modelSettings, modelVersionId: "SDAdImage"},
        negative_prompt:"amateur image,  blurry, ugly, low quality image, low resolution, thumbnail",
    }
]
//        appendPromnt: ", flash sheet, tattoo outline style, American Traditional, trending on artstation, @DeviantArt",




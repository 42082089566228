import { Button, IconButton, TextField, useMediaQuery } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import React, { useEffect } from 'react'
import { EditorSettings, App, ArtiusModelTraining, ArtiusTrainingTypes, DreamBoothSettings, DbName, ImageFiles, ProjectArtius, RoleTypes } from '../../../../models/shared_models';
import './trainingSettings.scss'
import { doc, getDoc, } from "firebase/firestore";
import { useDispatch, useSelector } from 'react-redux';
import { createTraining, CreateUpdateTrainingSettings, db,  uploadTrainingImages } from '../../../../services/web.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { updateAppState } from '../../../../app/states/appState';
import { EditorState, updateTrainingSettings } from '../../../../app/states/articleWatch';
import Accordion from '../../../../components/accordion/accordion';
import ImageIcon from '@mui/icons-material/Image';
import BuildIcon from '@mui/icons-material/Build';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';


function TrainingSettings() {

  const mobile = useMediaQuery('(min-width:600px)');

  //const mobile = useMediaQuery('(min-width:600px)');

  // get default settings from the store. This is the global state.
  const navigate = useNavigate();
  const editorSettings: EditorSettings = useSelector((state: any) => (state.imageEditorState as EditorState).editorSettings);
  const appState: App = useSelector((state: any) => state.appState.app as App);
  const trainingSettings: ArtiusModelTraining | null = useSelector((state: any) => (state.imageEditorState as EditorState).trainingSettings);
  const projectDetails: ProjectArtius | null = useSelector((state: any) => (state.imageEditorState as EditorState).projectSettings);
  const [onSave$] = React.useState(() => new Subject());
  const maxImages = 30;
  // too hold internal state of application.
  const [internalSettings, setInternalSettings] = React.useState<ArtiusModelTraining | null>(trainingSettings);
  //const [customClass, setShowCustomClass] = React.useState<boolean>(false);
  const [closePanel, setClosePanel] = React.useState<boolean>(!mobile);

  const dispatch = useDispatch()

  const updateinternalSettings = (payload: any) => {
    //setInternalSettings({ ...internalSettings, ...payload})
    setInternalSettings((prevState)=> {return { ...prevState, ...payload, inputs: { ...prevState?.inputs, ...payload.inputs } }})
  }

  const onFileChange = async (event: any) => {
    if (!trainingSettings) return null;

    dispatch(updateAppState({ loading: true }))
    //when fil upload
    uploadTrainingImages(event, trainingSettings, 30, appState.uid).then(x => {
      // when all images are uplaoded.
      console.log("Files uploaded")
      dispatch(updateTrainingSettings({ inputs: { files: x.files } }))

    }).catch(x => {
      // uploading failed.
      dispatch(updateAppState({ errorMsg: x.error }))
    }).finally(() => {
      dispatch(updateAppState({ loading: false }))
    })
  }

  const startTraining = async (training: ArtiusModelTraining) => {

    // if not a paid customer.
    if(appState.role== RoleTypes.FREE){
      // redirect user.
      navigate("/pricing");
      return null
    } 

    // to intiate API for training.
    dispatch(updateAppState({ loading: true }))
    //await autoSave(training)
    createTraining(training).then(data => {
      console.log("Training Created:", data.data)
      dispatch(updateAppState({ loading: false }))
      dispatch(updateTrainingSettings(data.data))

    }).catch((error: any) => {
      console.log("XXXX", error)
      dispatch(updateAppState({ errorMsg: "Failed to Start Training" }))
      dispatch(updateAppState({ loading: false }))
    });
  }


  // intialize autosave
  useEffect(() => {
    const subscription = onSave$.pipe(
      debounceTime(1000),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    ).subscribe((x: any) => autoSave(x));

    return () => {
      // Anything in here is fired on component unmount.
      // console.log("")
      subscription.unsubscribe();
    }
  }, [])


  useEffect(() => {

    console.log("---Update Training Settings---", trainingSettings)
    // Auto save whenever training setting change.
    onSave$.next(trainingSettings);
    updateinternalSettings(trainingSettings)
    //setInternalSettings({ ...internalSettings, ...globalModelSettings })
    //onModelChange(globalModelSettings.modelType, globalModelSettings.modelInputs);

    return () => {
      // Anything in here is fired on component unmount.
    }
  }, [trainingSettings])

  const autoSave = async (newTrainingSettings: ArtiusModelTraining) => {

    if (!newTrainingSettings || !projectDetails) return null
    await CreateUpdateTrainingSettings(appState.uid, projectDetails, newTrainingSettings).then(function () {
      // dispatch(updateAppState({ loading: false }))
      console.log("Saved:", newTrainingSettings);
    }).catch((error) => {
      console.log("ERROR", error)
      ///dispatch(updateAppState({ loading: false }))
      dispatch(updateAppState({ errorMsg: "Failed to create/update folder. Try refreshing the page and trying again." }))

    });
  };


  // get training data. accept models unqiue ID, we will use the project ID for default.
  const getTraingSettings = async (mUID: string) => {
    console.log("Get traning data")
    //test
    const docRef = doc(db, DbName.user, appState.uid, DbName.training, mUID, DbName.trainingSettings, mUID);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {

      const data: ArtiusModelTraining = docSnap.data() as ArtiusModelTraining;
      console.log("Retrieve Training Settings:", data);
      //set the data and also update with existing configs
      dispatch(updateTrainingSettings(data))
    }
  }

  // size for stable diffusion.
  const marks = [
    {
      value: 128,
      label: '',
    },
    {
      value: 256,
      label: '',
    },
    {
      value: 512,
      label: '',
    },
    {
      value: 768,
      label: '',
    },
    {
      value: 1024,
      label: '',
    },
  ];


  //this function returns the JSX.
  return (
    <React.Fragment>


      <div className={!closePanel ? "lineBox" : "lineBox lineBox__close"} style={editorSettings.loading ? { pointerEvents: 'none', opacity: 0.5 } : { pointerEvents: 'auto' }}>
    
      {closePanel &&
       <IconButton onPointerDown={()=>setClosePanel(false)} aria-label="delete">
       <SettingsIcon />
     </IconButton>
      }

      {!closePanel &&
    
     <React.Fragment>


      {!mobile &&
      <IconButton onPointerDown={()=>setClosePanel(true)} aria-label="delete">
        <CloseIcon />
      </IconButton>
}

        <FormControl fullWidth>
          <Accordion header="Training Settings" height={650}>

            <div className="spacer"></div>
            <div>
              {(trainingSettings && trainingSettings.inputs && trainingSettings?.modelType == ArtiusTrainingTypes.dreamBooth) &&
                <div>

                  <TextField id="outlined-basic" label="Model Name" variant="outlined"
                  FormHelperTextProps={{
                    className: "helperText"
                  }}
                    onChange={(event) => dispatch(updateTrainingSettings({ displayName: event.target.value }))}
                    defaultValue={trainingSettings.displayName}
                    helperText="Name of your model"

                  />

                  <div className="spacer"></div>

                  <TextField required id="outlined-basic" label="Instance Prompt" variant="outlined" FormHelperTextProps={{
                    className: "helperText"
                  }}
                    onChange={(event) => dispatch(updateTrainingSettings({ inputs: { instance_prompt: event.target.value } }))}
                    defaultValue={(trainingSettings.inputs as DreamBoothSettings).instance_prompt}
                    placeholder="photo of artius.john" helperText="Promnt to describe your subject, must be unique eg. artius.[subjectName]" />

                  <div className="spacer"></div>
                  <div>Subject Type</div>
            
                  <div className="spacer"></div>
                <TextField required FormHelperTextProps={{
                    className: "helperText"
                  }} id="outlined-basic"
                    defaultValue={(trainingSettings.inputs as DreamBoothSettings).class_prompt}
                    onChange={(event) => dispatch(updateTrainingSettings({ inputs: { class_prompt: event.target.value } }))}
                    helperText="Prompt to describe a broad category that fits your subject, like : photo of a person/shoes/house, etc"
                    label="Class Prompt" variant="outlined" placeholder="photo of a person" />

                  <div className="spacer"></div>


                  <Button startIcon={<ImageIcon />} disabled={(trainingSettings.inputs as DreamBoothSettings).files.length > maxImages} className="artiusButtonOutline btn empBtn" component="label" variant="outlined">
                    Upload Images
                    <input hidden accept="image/*" type="file" multiple onChange={onFileChange} />
                  </Button>
                  <div className="spacer"></div>
                  <Button startIcon={<BuildIcon />} onClick={() => startTraining(trainingSettings)} disabled={!(trainingSettings.inputs as DreamBoothSettings).instance_prompt || !(trainingSettings.inputs as DreamBoothSettings).class_prompt || (trainingSettings.inputs as DreamBoothSettings).files.length < 5} className="artiusButtonOutline btn empBtn" component="label" variant="outlined">
                    Start Training
                  </Button>
                  <div className="spacer"></div>
                  *Upload a minimum of 5 images to start training. All images must be 500x500 jpg or png.
                  <div className="spacer"></div>
                  {/*  <Button  startIcon={<SaveIcon />}  onClick={()=>autoSave(trainingSettings)} className="artiusButtonOutline btn empBtn" component="label" variant="outlined">
                    Save Changes
                  </Button> */}
                </div>
              }


            </div>



          </Accordion>



        </FormControl>

        </React.Fragment> }
      </div>



    </React.Fragment>
  );
}

export default TrainingSettings


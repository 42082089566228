import { Button, LinearProgress } from '@mui/material';
import { doc, onSnapshot } from 'firebase/firestore';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { updateAppState } from '../../app/states/appState';
import { subDetails } from '../../models/configs';
import { App, DbName, RoleTypes } from '../../models/shared_models';
import { db, getSubDetails, openUserSubPortal } from '../../services/web.service';
import styles from './subProgress.module.scss'

interface Props {

  height?: number
  open?: boolean
}


function SubProgress(props: Props) {
  //this function returns the JSX.
  const appState: App = useSelector((state: any) => state.appState.app as App);
  const [percentage, setPercentage] = React.useState<number>(0);
  const navigate = useNavigate();
  const dispatch = useDispatch()


  const openBilling = () =>{
    //open billing imgae
    if(appState.role==RoleTypes.FREE){
      // if free then we redirect to sub page
      navigate("/pricing");
    }else{
      dispatch(updateAppState({ loading: true }))
      openUserSubPortal()
    }
  }

  // component mounting and unmounting
  useEffect(() => {
    // update all images.
    let unsub: any = null;
    const docRef = doc(db, DbName.user, appState.uid)

    unsub = onSnapshot(docRef, (querySnapshot) => {

      // check if any image is pending.
      if (querySnapshot.exists()) {
        const data = querySnapshot.data();
        
        // calculate percentage.
        const maxReqeusts = getSubDetails(appState.role || RoleTypes.FREE)?.maxRequest || 0;
        const perc = (data.requests/maxReqeusts)*100
        setPercentage( data.requests>maxReqeusts ? 100 : perc )
        dispatch(updateAppState({ requests: data.requests }))
        console.log("Requests", percentage, data.requests, maxReqeusts, appState.role)
      }

    });


    return () => {
      // Anything in here is fired on component unmount.
      if (unsub) unsub();
    }
  }, [])

  return (
    <React.Fragment>
      {appState.role &&
        <div className={styles.subBox}>

          {getSubDetails(appState.role)?.name} - $<span>{getSubDetails(appState.role)?.price}</span>/Month
          <p> <LinearProgress sx={{ height: 10, borderRadius: 5, backgroundColor: "#343356" }} variant="determinate" value={percentage} /></p>

            <Button onClick={openBilling} type="submit" className="orangeButton" variant="contained">Upgrade</Button>
           

        </div>
      }
    </React.Fragment>
  );
}

export default SubProgress
